import { APIS } from "../../utils/apis";
import { Chapter } from "../modals/Chapter";
import { ChaptersProgress } from "../modals/ChaptersProgress";
import { authenticatedDelete, authenticatedGet } from "../utils";

export const getChapters = async (topicId: string): Promise<Chapter[]> => {
  return (await authenticatedGet(APIS.getChapters(topicId))).chapters;
};

export const getChapter = async (chapterId: string): Promise<Chapter> => {
  return await authenticatedGet(APIS.getChapter(chapterId));
};

export const getChaptersProgress = async (
  topicId: string
): Promise<ChaptersProgress> => {
  return await authenticatedGet(APIS.getChaptersProgress(topicId));
};

export const resetChapterProgress = async (
  chapterId: string
): Promise<void> => {
  return await authenticatedDelete(APIS.getResetChapter(chapterId));
};
