import { Question } from "../api/modals/Question";
import { QuestionResult, User } from "../utils/types";

export type UserState = {
  currUser: User | null;
};
export type QuestionsState = {
  questionList: Question[];
  currQuestionIndex: number;
  totalNumberOfQuestions: number;
  numberOfLockedQuestions: number;
  questionResult: QuestionResult | null;
  correctAnswer: string[] | null;
};
export const SET_CURR_USER = "SET_CURR_USER";
export const CLEAR_CURR_USER = "CLEAR_CURR_USER";
export const SET_QUESTION_LIST = "SET_QUESTION_LIST";
export const SET_CURR_QUESTION_INDEX = "SET_CURR_QUESTION_INDEX";
export const CLEAR_QUESTIONS_AND_CURR_QUESTION_INDEX =
  "CLEAR_QUESTIONS_AND_CURR_QUESTION_INDEX";
export const SET_RESULT = "SET_RESULT";
export const CLEAR_RESULT = "CLEAR_RESULT";
export type SetUser = { type: typeof SET_CURR_USER; payload: User };
export type ClearUser = { type: typeof CLEAR_CURR_USER };
export type SetQuestionList = {
  type: typeof SET_QUESTION_LIST;
  payload: {
    totalNumOfQuestions: number;
    numOfLockedQuestions: number;
    questions: Question[];
  };
};
export type SetCurrQuestionIndex = {
  type: typeof SET_CURR_QUESTION_INDEX;
  payload: number;
};
export type ClearQuestionsAndCurrQuestionIndex = {
  type: typeof CLEAR_QUESTIONS_AND_CURR_QUESTION_INDEX;
};
export type SetResult = {
  type: typeof SET_RESULT;
  payload: {
    questionResult: QuestionResult;
    correctAnswer: string[];
  };
};
export type ClearResult = {
  type: typeof CLEAR_RESULT;
};
export type ActionTypes =
  | SetUser
  | ClearUser
  | SetQuestionList
  | SetCurrQuestionIndex
  | ClearQuestionsAndCurrQuestionIndex
  | SetResult
  | ClearResult;
