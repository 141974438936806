import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button } from "@mui/material";
import { FormikHelpers, useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useTypedSelector } from "../../redux/store";
import {
  QUESTION_RESULT_CORRECT_ANSWER,
  QUESTION_RESULT_WRONG_ANSWER,
} from "../../utils/constants";
import { palette } from "../../utils/muiTheme";
import { TextField } from "../TextField";

type Props = {
  onSubmit: (answer: string) => void;
};

const fillInScheme = Yup.object().shape({
  answer: Yup.string().required("Answer required"),
});

type FillInSchema = Yup.InferType<typeof fillInScheme>;

const initialValues: FillInSchema = {
  answer: "",
} as FillInSchema;

export const FillInBlock: React.FC<Props> = ({ onSubmit }) => {
  const result = useTypedSelector((state) => state.questions.questionResult);

  const onFormSubmit = (
    formValues: FillInSchema,
    { resetForm, setSubmitting }: FormikHelpers<FillInSchema>
  ) => {
    setSubmitting(true);
    onSubmit(formValues.answer);
    setSubmitting(false);
    resetForm();
  };

  const { handleSubmit, touched, values, errors, handleChange } =
    useFormik<FillInSchema>({
      initialValues: initialValues,
      validationSchema: fillInScheme,
      onSubmit: onFormSubmit,
    });

  return (
    <Box
      py={4}
      sx={{
        alignSelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box py={2}>
        {result && result === QUESTION_RESULT_CORRECT_ANSWER ? (
          <CheckCircleIcon sx={{ color: palette.green }} />
        ) : result && result === QUESTION_RESULT_WRONG_ANSWER ? (
          <CancelIcon sx={{ color: palette.red }} />
        ) : (
          <></>
        )}
      </Box>
      <form onSubmit={handleSubmit}>
        <TextField
          value={values.answer}
          name="answer"
          onChange={handleChange}
          placeholder="Your answer here"
          error={!!touched.answer && !!errors.answer}
          helperText={!!touched.answer && errors.answer}
        />
        <Button fullWidth type="submit">
          Submit answer
        </Button>
      </form>
    </Box>
  );
};
