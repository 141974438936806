import { Box, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { URLS } from "../../utils/urls";
import { UserMenu } from "./UserMenu";

export const AccountManager: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box px={1} sx={{ display: "flex" }}>
      <Button onClick={() => navigate(URLS.MY_ACCOUNT)} variant="outlined">
        My Account
      </Button>
      <Box pl={1}>
        <UserMenu />
      </Box>
    </Box>
  );
};
