import { Box, Button, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { logIn, signUp } from "../../api/services/User";
import { TextField } from "../../components/TextField";
import { setCurrUser } from "../../redux/actions/userActions";
import { SESSION_STORAGE_KEY_JWT_TOKEN } from "../../utils/constants";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";

const registerSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  name: Yup.string().required("Name is required"),
  password: Yup.string().required("Password is required"),
  repeatedPassword: Yup.string()
    .required("Password confirmation is required")
    .oneOf([Yup.ref("password")], "Password confirmation must match"),
});

type RegisterSchema = Yup.InferType<typeof registerSchema>;

const initialValues: RegisterSchema = {
  email: "",
  name: "",
  password: "",
  repeatedPassword: "",
} as RegisterSchema;

export const CreateAccount: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const onFormSubmit = async (formValues: RegisterSchema) => {
    const { email, name, password } = formValues;

    try {
      const { role, user } = await signUp(email, email, name, password, false);

      const { token } = await logIn(email, password);

      localStorage.setItem(SESSION_STORAGE_KEY_JWT_TOKEN, token);

      dispatch(
        setCurrUser({
          id: user.id,
          name: user.name,
          username: user.username,
          role: role.name,
          email,
          activationToken: user.activation_token,
          stripeCustomerId: user.stripe_customer_id,
          isActive: user.is_active,
          isSubscribed: user.is_subscribed,
          subscriptionTill: user.subscription_till
            ? dayjs(user.subscription_till).toDate()
            : null,
        })
      );

      navigate(URLS.MY_ACCOUNT);
    } catch (error) {
      enqueueSnackbar("Unable to create account", {
        variant: "error",
      });
    }
  };

  const { handleSubmit, touched, values, errors, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: registerSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <Box pt={2} pr={4} minWidth={500}>
      <Typography variant="h4" color={palette.lightPurple}>
        Welcome !
      </Typography>
      <form onSubmit={handleSubmit}>
        <Stack py={2} spacing={2}>
          <TextField
            fullWidth
            title="Name"
            name="name"
            value={values.name}
            onChange={handleChange}
            error={!!touched.name && !!errors.name}
            helperText={!!touched.name && errors.name}
          />
          <TextField
            fullWidth
            title="Email"
            name="email"
            type="email"
            value={values.email}
            onChange={handleChange}
            error={!!touched.email && !!errors.email}
            helperText={!!touched.email && errors.email}
          />
          <TextField
            fullWidth
            title="Password"
            name="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            error={!!touched.password && !!errors.password}
            helperText={!!touched.password && errors.password}
          />
          <TextField
            fullWidth
            title="Confirm password"
            name="repeatedPassword"
            type="password"
            value={values.repeatedPassword}
            onChange={handleChange}
            error={!!touched.repeatedPassword && !!errors.repeatedPassword}
            helperText={!!touched.repeatedPassword && errors.repeatedPassword}
          />
        </Stack>
        <Box pt={1} sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" type="submit">
            Register
          </Button>
        </Box>
      </form>
    </Box>
  );
};
