import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getChapter } from "../../api/services/Chapter";
import { getSubject } from "../../api/services/Subject";
import { getTopic } from "../../api/services/Topic";
import { URLS } from "../../utils/urls";
import { Bar } from "./Bar";

type LocationLink = {
  name: string;
  link: string;
};

const subjectListLocationLink: LocationLink[] = [
  { name: "Home", link: URLS.HOME },
  { name: "Subjects", link: URLS.SUBJECT_LIST },
];

const accountLocationLink: LocationLink[] = [
  { name: "Home", link: URLS.HOME },
  { name: "My Account", link: URLS.MY_ACCOUNT },
];

const gameLink: LocationLink[] = [
  { name: "Home", link: URLS.HOME },
  { name: "Game", link: URLS.GAME },
];

const createSubjectLocationLink = async (
  subjectId: string
): Promise<LocationLink[]> => {
  const { name } = await getSubject(subjectId);
  const result = [
    ...subjectListLocationLink,
    { name, link: URLS.getSubject(subjectId) } as LocationLink,
  ] as LocationLink[];
  return result;
};

const createTopicLocationLink = async (
  topicId: string
): Promise<LocationLink[]> => {
  const { name, subject } = await getTopic(topicId);
  const subjectLocationLink = await createSubjectLocationLink(
    subject.toString()
  );

  return [...subjectLocationLink, { name, link: URLS.getTopic(topicId) }];
};

const createChapterLocationLink = async (chapterId: string) => {
  const { name, topic } = await getChapter(chapterId);
  const topicLocationLink = await createTopicLocationLink(topic.toString());

  return [...topicLocationLink, { name, link: URLS.getChapter(chapterId) }];
};

const createLocationLink = async (
  currLocation: string,
  id: string
): Promise<LocationLink[]> => {
  switch (currLocation) {
    case "subject":
      return await createSubjectLocationLink(id);
    case "topic":
      return await createTopicLocationLink(id);
    case "chapter":
      return await createChapterLocationLink(id);
    case "account":
      return accountLocationLink;
    case "game":
      return gameLink;
    default:
      return subjectListLocationLink;
  }
};

export const LocationBar: React.FC = () => {
  const { pathname } = useLocation();

  const [locationLinkList, setLocationLinkList] = useState<
    LocationLink[] | null
  >(null);

  const currLocation =
    pathname.split("/").length >= 2 ? pathname.split("/")[1] : null;

  useEffect(() => {
    const updateLocationLinkList = async () => {
      const result = await createLocationLink(
        currLocation ?? "",
        currLocation ? pathname.split("/")[2] : ""
      );
      setLocationLinkList(result);
    };
    updateLocationLinkList();
  }, [currLocation, pathname]);

  return (
    <Box py={2} sx={{ display: "flex" }}>
      {locationLinkList && <Bar locationLinkList={locationLinkList} />}
    </Box>
  );
};
