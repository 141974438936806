import { URLS } from "../utils/urls";
import { post } from "./utils";

export const refreshToken = async (token: string) => {
  const body = JSON.stringify({
    token,
  });

  //TODO: to be completed
  const result = await post(URLS.REFRESH_TOKEN, body);
  return result;
};
