import { Box } from "@mui/material";
import React from "react";
import { NavBar } from "../NavBar";
import { FeedbackForm } from "./FeedbackForm";
import { Footer } from "./Footer";

type Props = {
  children?: React.ReactNode;
  shouldDisplayFooter?: boolean;
};

export const Page: React.FC<Props> = ({ children, shouldDisplayFooter }) => {
  return (
    <Box>
      <NavBar />
      <Box
        px={6}
        pt={4}
        pb={12}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        {children}
      </Box>
      {shouldDisplayFooter && (
        <>
          <FeedbackForm />
          <Footer />
        </>
      )}
    </Box>
  );
};
