import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getUser, logIn } from "../../api/services/User";
import { Page } from "../../components/Page";
import { TextField } from "../../components/TextField";
import { setCurrUser } from "../../redux/actions/userActions";
import { SESSION_STORAGE_KEY_JWT_TOKEN } from "../../utils/constants";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";

const loginSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

type LoginSchema = Yup.InferType<typeof loginSchema>;

const initialValues: LoginSchema = {
  email: "",
  password: "",
} as LoginSchema;

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const updateCurrUser = async () => {
    const currUser = await getUser();
    dispatch(setCurrUser(currUser));
  };

  const onFormSubmit = async (formValues: LoginSchema) => {
    const { email, password } = formValues;
    try {
      const { token } = await logIn(email, password);
      localStorage.setItem(SESSION_STORAGE_KEY_JWT_TOKEN, token);
      await updateCurrUser();
      navigate(URLS.HOME);
    } catch (error) {
      enqueueSnackbar("Error to log in", {
        variant: "error",
      });
    }
  };

  const { handleSubmit, touched, values, errors, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <Page>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box minWidth={500}>
          <Box pt={2}>
            <Typography variant="h4" color={palette.lightPurple}>
              Welcome !
            </Typography>
            <Box pt={2} pb={4}>
              <Typography variant="h3" color={palette.lightPink}>
                Login
              </Typography>
            </Box>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box pt={1}>
              <TextField
                title="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                error={!!touched.email && !!errors.email}
                helperText={!!touched.email && errors.email}
              />
            </Box>
            <Box pt={1} pb={4}>
              <TextField
                title="Password"
                name="password"
                type="password"
                value={values.password}
                onChange={handleChange}
                error={!!touched.password && !!errors.password}
                helperText={!!touched.password && errors.password}
              />
            </Box>
            <Box pr={1}>
              <Button fullWidth variant="contained" type="submit">
                Login
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Page>
  );
};
