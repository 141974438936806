import { APIS } from "../../utils/apis";
import { authenticatedPost } from "../utils";

export const createSubscription = async (priceId: string): Promise<string> => {
  const body = JSON.stringify({
    price_id: priceId,
  });
  const { session_url } = await authenticatedPost(
    APIS.CREATE_SUBSCRIPTION,
    body
  );
  return session_url;
};
