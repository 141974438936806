import { Alert } from "@mui/material";
import React from "react";
import { isMobile } from "react-device-detect";

type Props = {
  children?: React.ReactNode;
};

export const NotificationProvider: React.FC<Props> = ({ children }) => {
  const { outerWidth: width } = window;

  const alertWidth = `${width * 0.83}px`;
  return (
    <>
      {isMobile && (
        <Alert sx={{ width: alertWidth }} variant="filled" severity="error">
          For the best experience, we recommend using a tablet or PC to explore
          our website. While you can still access all of our content from your
          mobile device, certain features and functionalities may be optimised
          for larger screens.
        </Alert>
      )}
      {children}
    </>
  );
};
