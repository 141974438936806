import { Stack } from "@mui/material";
import React from "react";
import { useTypedSelector } from "../../redux/store";
import { OptionButton } from "./OptionButton";

type Props = {
  choices: string[];
  onSubmit: (answer: string) => void;
};

export const ChooseOneBlock: React.FC<Props> = ({ choices, onSubmit }) => {
  const correctAnswer = useTypedSelector(
    (state) => state.questions.correctAnswer
  );
  const questionResult = useTypedSelector(
    (state) => state.questions.questionResult
  );

  return (
    <Stack mt={2} spacing={2}>
      <Stack direction="row" spacing={2}>
        <OptionButton
          optionKey="A"
          optionValue={choices[0]}
          onClick={() => onSubmit(choices[0])}
          providedAnswer={correctAnswer ?? undefined}
          result={questionResult ?? undefined}
        />
        <OptionButton
          optionKey="B"
          optionValue={choices[1]}
          onClick={() => onSubmit(choices[1])}
          providedAnswer={correctAnswer ?? undefined}
          result={questionResult ?? undefined}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        {choices.length > 2 && (
          <OptionButton
            optionKey="C"
            optionValue={choices[2]}
            onClick={() => onSubmit(choices[2])}
            providedAnswer={correctAnswer ?? undefined}
            result={questionResult ?? undefined}
          />
        )}

        {choices.length > 3 && (
          <OptionButton
            optionKey="D"
            optionValue={choices[3]}
            onClick={() => onSubmit(choices[3])}
            providedAnswer={correctAnswer ?? undefined}
            result={questionResult ?? undefined}
          />
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        {choices.length > 4 && (
          <OptionButton
            optionKey="E"
            optionValue={choices[4]}
            onClick={() => onSubmit(choices[4])}
            providedAnswer={correctAnswer ?? undefined}
            result={questionResult ?? undefined}
          />
        )}
      </Stack>
    </Stack>
  );
};
