import { Typography } from "@mui/material";
import React from "react";

type Props = {
  videoLink: string;
};

export const IntroVideo: React.FC<Props> = ({ videoLink }) => {
  return (
    <>
      {videoLink.length ? (
        <iframe
          allow="autoplay"
          title="title"
          className="spotlightr"
          style={{ width: "800px", height: "425px" }}
          src={videoLink}
          name="videoPlayer"
        />
      ) : (
        <Typography variant="h5">Intro video not available</Typography>
      )}
    </>
  );
};
