import { Box, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../utils/muiTheme";
import { LocationBar } from "../LocationBar";

export const PageHeader: React.FC = () => {
  return (
    <Box mb={1} sx={{ height: 252, minWidth: 1280, alignSelf: "stretch" }}>
      <Box
        sx={{
          height: 227,
          minWidth: 300,
          display: "flex",
          borderRadius: 2,
          alignSelf: "stretch",
          backgroundColor: palette.white,
        }}
      >
        <Box pl={8} pt={6} sx={{ flexGrow: 1 }}>
          <Typography color={palette.black} variant="h3">
            11+
          </Typography>
          <Typography color={palette.black} variant="h3">
            learn and play
          </Typography>
        </Box>
        <Box
          pr={2}
          component="img"
          src={`${process.env.PUBLIC_URL}/course-page-title.png`}
        />
      </Box>
      <Box pl={2}>
        <LocationBar />
      </Box>
    </Box>
  );
};
