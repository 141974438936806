import { Box, Button, Paper, Typography } from "@mui/material";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import React from "react";
import { useNavigate } from "react-router-dom";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";
type Props = {
  id: string;
  name: string;
  imgName: string;
  progress: number;
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export const SubjectCard: React.FC<Props> = ({
  id,
  name,
  imgName,
  progress,
}) => {
  const navigate = useNavigate();

  return (
    <Paper variant="outlined" sx={{ width: 290, height: 329 }}>
      <Box
        sx={{
          width: 290,
          height: 290,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box component="img" src={imgName} />
        <Box py={2} textAlign="center">
          <Typography color={palette.black} variant="h5">
            {name}
          </Typography>
        </Box>
        {/* 
        TODO: Uncomment once testing is done
        <Box py={2}>
          <CircularProgressWithLabel value={progress} />
        </Box> */}
        <Button
          variant="outlined"
          onClick={() => navigate(URLS.getSubject(id))}
        >
          Start
        </Button>
      </Box>
    </Paper>
  );
};
