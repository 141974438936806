import dayjs from "dayjs";
import { APIS } from "../../utils/apis";
import { User } from "../../utils/types";
import { UserDetails } from "../modals/UserDetails";
import { authenticatedGet, post } from "../utils";

export const logIn = async (username: string, password: string) => {
  const body = JSON.stringify({
    username,
    password,
  });

  return await post(APIS.LOG_IN, body);
};

export const signUp = async (
  email: string,
  username: string,
  name: string,
  password: string,
  isAdmin: boolean
): Promise<UserDetails> => {
  const body = JSON.stringify({
    email,
    password,
    username,
    name,
    is_admin: isAdmin,
  });

  return (await post(APIS.SIGN_UP, body)) as UserDetails;
};

export const getUser = async (): Promise<User> => {
  const {
    id,
    name,
    role,
    email,
    username,
    activation_token: activationToken,
    stripe_customer_id: stripeCustomerId,
    is_active: isActive,
    is_subscribed: isSubscribed,
    subscription_till,
  } = await authenticatedGet(APIS.USER_DETAILS);

  return {
    id,
    name,
    username,
    role,
    email,
    activationToken,
    stripeCustomerId,
    isActive,
    isSubscribed,
    subscriptionTill: subscription_till
      ? dayjs(subscription_till).toDate()
      : null,
  };
};
