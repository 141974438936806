import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getChapter } from "../../api/services/Chapter";
import { getQuestions } from "../../api/services/Question";
import { ExamPractice } from "../../components/ExamPractice";
import { Page } from "../../components/Page";
import { SubPageHeader } from "../../components/PageHeader";
import { Quizzes } from "../../components/Quizzes";
import {
  CHAPTER_SECTION_EXAM_PRACTICE,
  CHAPTER_SECTION_QUIZZES,
  QUERY_KEY_CHAPTER,
  QUERY_KEY_QUESTIONS,
} from "../../utils/constants";
import { ChapterSection } from "../../utils/types";
import { ChapterSectionBar } from "./ChapterSectionBar";
import { IntroVideo } from "./IntroVideo";

export const Chapter: React.FC = () => {
  const [currSection, setCurrSection] = useState<ChapterSection>(
    CHAPTER_SECTION_EXAM_PRACTICE
  );
  const { chapterId } = useParams();

  if (!chapterId) {
    throw new Error("Chapter id is empty");
  }

  const { data: chapter } = useQuery([QUERY_KEY_CHAPTER, chapterId], () =>
    getChapter(chapterId)
  );

  const { data: questions } = useQuery([QUERY_KEY_QUESTIONS, chapterId], () =>
    getQuestions(chapterId)
  );

  useEffect(() => {
    if (questions && !questions.exam_questions) {
      setCurrSection(CHAPTER_SECTION_QUIZZES);
    }
  }, [questions]);

  // TODO: To be completed
  const progresss = 0;

  return (
    <Page>
      {chapter && (
        <>
          <SubPageHeader
            title={chapter.name}
            progress={progresss}
            showBackgroundImg={false}
          />
          <ChapterSectionBar
            currSection={currSection}
            setCurrSection={setCurrSection}
          />
          <Box pt={4}>
            {currSection === CHAPTER_SECTION_QUIZZES && chapterId ? (
              <Quizzes chapterId={chapterId} />
            ) : currSection === CHAPTER_SECTION_EXAM_PRACTICE && chapterId ? (
              <ExamPractice chapterId={chapterId ?? ""} />
            ) : (
              <IntroVideo videoLink={chapter.intro_video} />
            )}
          </Box>
        </>
      )}
    </Page>
  );
};
