import { Question } from "../api/modals/Question";
import {
  QUESTION_RESULT_CORRECT_ANSWER,
  QUESTION_RESULT_WRONG_ANSWER,
} from "./constants";

export const hasQuestionBeenAnswered = (question: Question): boolean => {
  const { user_answer_status } = question;

  return (
    !!user_answer_status &&
    [QUESTION_RESULT_CORRECT_ANSWER, QUESTION_RESULT_WRONG_ANSWER].includes(
      user_answer_status
    )
  );
};

export const getTheIndexOfNextUnansweredQuestion = (
  questions: Question[]
): number => {
  const firstQuestion = questions.find((q) => !hasQuestionBeenAnswered(q));
  return firstQuestion ? questions.indexOf(firstQuestion) : 0;
};

export const getSubjectImageName = (subjectName: string) => {
  const fileName = subjectName.toLocaleLowerCase().replaceAll(" ", "-");
  return `${process.env.PUBLIC_URL}/subject-${fileName}.png`;
};
