import { Box, Modal } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createSubscription } from "../../api/services/Subscription";
import { getUser } from "../../api/services/User";
import { Button } from "../../components/Button";
import { setCurrUser } from "../../redux/actions/userActions";
import {
  PRICE_ID_ANNUAL_PLAN,
  PRICE_ID_MONTHLY_PLAN,
  PRICE_ID_QUARTERLY_PLAN,
} from "../../utils/constants";
import { Package } from "./Package";

export const Packages: React.FC = () => {
  const [isMakingPayment, setIsMakingPayment] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    justifyContent: "center",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    p: 4,
  };

  const onPurchasePlan = async (priceId: string) => {
    const url = await createSubscription(priceId);
    window.open(url);
    setIsMakingPayment(true);
  };

  const onPaymentComplete = async () => {
    enqueueSnackbar("Checking payment", {
      variant: "info",
    });
    setIsMakingPayment(false);
    const currUser = await getUser();
    if (currUser.isSubscribed) {
      dispatch(setCurrUser(currUser));
      enqueueSnackbar("Payment successful", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Payment unsuccessful", {
        variant: "error",
      });
    }
  };

  return (
    <Box
      py={4}
      sx={{ flexGrow: 1, display: "flex", flexWrap: "wrap" }}
      flexGrow={1}
    >
      <Modal open={isMakingPayment}>
        <Box sx={style}>
          <Button
            sx={{ justifySelf: "center", alignSelf: "center" }}
            variant="contained"
            onClick={onPaymentComplete}
          >
            Payment has been made
          </Button>
        </Box>
      </Modal>
      <Package
        packageName="Monthly Package"
        price={7}
        onPurchasePlan={() => onPurchasePlan(PRICE_ID_MONTHLY_PLAN)}
      />
      <Package
        packageName="Quarterly Package"
        price={18}
        onPurchasePlan={() => onPurchasePlan(PRICE_ID_QUARTERLY_PLAN)}
      />
      <Package
        packageName="Annual Package"
        price={60}
        onPurchasePlan={() => onPurchasePlan(PRICE_ID_ANNUAL_PLAN)}
      />
    </Box>
  );
};
