import { Box, Button } from "@mui/material";
import React from "react";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { resetChapterProgress } from "../../api/services/Chapter";
import { setCurrQuestionIndex } from "../../redux/actions/questionsActions";
import { QUERY_KEY_QUESTIONS } from "../../utils/constants";

type Props = {
  chapterId: string;
  disabled?: boolean;
};

export const ResetProgressButton: React.FC<Props> = ({
  chapterId,
  disabled,
}) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const onClickHandler = async () => {
    await resetChapterProgress(chapterId);

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_QUESTIONS, chapterId],
    });

    dispatch(setCurrQuestionIndex(0));
  };

  return (
    <Box my={2}>
      <Button
        disabled={disabled}
        onClick={async () => await onClickHandler()}
        variant="contained"
      >
        Reset Progress
      </Button>
    </Box>
  );
};
