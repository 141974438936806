import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { EMAIL_ADDRESS_JEFF, PHONE_NUMBER_JEFF } from "../../utils/constants";
import { palette } from "../../utils/muiTheme";

export const HelpButton: React.FC = () => {
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <Button onClick={() => setIsHelpOpen(true)}>Help</Button>
      <Modal open={isHelpOpen} onClose={() => setIsHelpOpen(false)}>
        <Box sx={style}>
          <Typography color={palette.black} variant="h5">
            Please contact
          </Typography>
          <Typography color={palette.black} variant="body1">
            {EMAIL_ADDRESS_JEFF}
          </Typography>
          <Typography color={palette.black} variant="body1">
            {PHONE_NUMBER_JEFF}
          </Typography>
        </Box>
      </Modal>
    </>
  );
};
