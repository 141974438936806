import { LinearProgress as MuiLinearProgress } from "@mui/material";
import React from "react";

type Props = {
  value: number;
};

export const LinearProgress: React.FC<Props> = ({ value }) => {
  return (
    <MuiLinearProgress
      sx={{
        m: 1,
        height: "25px",
        borderRadius: "12px",
        border: "1px solid",
      }}
      variant="determinate"
      value={value}
    />
  );
};
