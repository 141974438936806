import { Box, Link as MuiLink, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  text: string;
  url: string;
};

export const Link: React.FC<Props> = ({ text, url }) => {
  const navigate = useNavigate();

  return (
    <Box>
      <MuiLink
        underline="hover"
        sx={{ cursor: "pointer" }}
        onClick={() => navigate(url)}
      >
        <Typography variant="body1" sx={{ pt: 2 }}>
          {text}
        </Typography>
      </MuiLink>
    </Box>
  );
};
