import { Box } from "@mui/material";
import React from "react";
import { Page } from "../../components/Page";
import { ServicesBlock } from "./ServicesBlock";
import { TopBlock } from "./TopBlock";

export const Home: React.FC = () => {
  return (
    <Page shouldDisplayFooter>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "1240px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TopBlock />
          <ServicesBlock />  
        </Box>
      </Box>
    </Page>
  );
};
