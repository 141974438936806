import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Bar } from "../../components/LocationBar";
import { Page } from "../../components/Page";
import { URLS } from "../../utils/urls";

type QuestionAndAnswer = {
  question: string;
  answer: string;
};

export const FAQ: React.FC = () => {
  const locationLinkList = [
    { name: "Home", link: URLS.HOME },
    { name: "FAQ", link: URLS.FAQ },
  ];

  const questionsAndAnswers: QuestionAndAnswer[] = [
    {
      question: "What is 11+ Learn & Play?",
      answer:
        "11+ Learn & Play is an online platform designed to help children prepare for the 11+ exams. It includes engaging videos and interactive games that cover a wide range of topics, from Maths and English to Verbal and Non-Verbal Reasoning.",
    },
    {
      question: "How does 11+ Learn & Play work?",
      answer:
        "After signing up to 11+ Learn & Play, your child will have access to all the learning materials on the platform. They can watch videos, play quizzes and games, and track their progress over time.",
    },
    {
      question: "Is 11+ Learn & Play suitable for all children?",
      answer:
        "Yes, 11+ Learn & Play is suitable for all children who are preparing for the 11+ exams. Our platform covers a wide range of topics and is designed to be accessible and engaging for children of all learning styles.",
    },
    {
      question: "Can I track my child's progress on 11+ Learn & Play?",
      answer:
        "Yes, 11+ Learn & Play includes a progress tracker that allows you to see how your child is doing over time.",
    },
    {
      question: "Can my child use 11+ Learn & Play on a mobile device?",
      answer:
        "Yes, 11+ Learn & Play is accessible from any device with an internet connection, including mobile phones and tablets.",
    },
    {
      question: "Is there a free trial available?",
      answer:
        "Yes, we offer a free trial of 11+ Learn & Play. You can sign up for the trial on our website.",
    },
    {
      question: "How much does 11+ Learn & Play cost?",
      answer:
        "The cost of 11+ Learn & Play varies depending on the subscription plan you choose. You can see pricing details on our website.",
    },
    {
      question: "Is there a money-back guarantee?",
      answer:
        "Yes, we offer a 30-day money-back guarantee for all our subscription plans. If you're not satisfied with 11+ Learn & Play within the first 30 days, we'll refund your money.",
    },
    {
      question: "How long does a subscription to 11+ Learn & Play last?",
      answer:
        "Subscriptions to 11+ Learn & Play last for either a month, three months or one year from the date of purchase.",
    },
    {
      question: "Can my child access 11+ Learn & Play from multiple devices?",
      answer:
        "Yes, your child can access 11+ Learn & Play from any device with an internet connection.",
    },
    {
      question: "How often is new content added to 11+ Learn & Play?",
      answer:
        "We are constantly updating and adding new content to 11+ Learn & Play to ensure that it remains fresh and engaging for our students.",
    },
    {
      question:
        "Is there a limit to how much my child can use 11+ Learn & Play?",
      answer:
        "No, there is no limit to how much your child can use 11+ Learn & Play. They can access the platform as often as they like throughout their subscription period.",
    },
    {
      question: "Can I cancel my subscription to 11+ Learn & Play?",
      answer:
        "Yes, you can cancel your subscription to 11+ Learn & Play at any time. Simply log in to your account and follow the instructions to cancel.",
    },
    {
      question: "How do I contact customer support for 11+ Learn & Play?",
      answer:
        "If you have any questions or concerns about 11+ Learn & Play, you can contact our customer support team via email or through the contact form on our website. We aim to respond to all queries within 24 hours.",
    },
  ];

  return (
    <Page>
      <Box pl={8}>
        <Bar locationLinkList={locationLinkList} />
      </Box>
      <Box pt={4} px={8}>
        <Stack spacing={4}>
          <Typography variant="h3">Frequently Asked Questions</Typography>
          {questionsAndAnswers.map(({ question, answer }) => (
            <div key={question}>
              <Typography variant="h5">{question}</Typography>
              <Typography variant="body1">{answer}</Typography>
            </div>
          ))}
        </Stack>
      </Box>
    </Page>
  );
};
