import { Box, Button, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocationBar } from "../../components/LocationBar";
import { Page } from "../../components/Page";
import { clearCurrUser } from "../../redux/actions/userActions";
import { useTypedSelector } from "../../redux/store";
import {
  DISPLAY_DATE_STRING,
  SESSION_STORAGE_KEY_JWT_TOKEN,
} from "../../utils/constants";
import { URLS } from "../../utils/urls";
import { Packages } from "./Packages";

// type DisplayBlock = "ACCOUNT" | "ACHIEVEMENTS";

export const MyAccount: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currUser = useTypedSelector((state) => state.user.currUser);

  // const [displayBlock, setDisplayBlock] = useState<DisplayBlock>("ACCOUNT");

  return (
    <Page>
      <LocationBar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Box component="img" src="./profile.png" />

        {/* 
        //TODO:  Achievements not availbale
        <Box
          py={2}
          sx={{
            display: "flex",
            width: 260,
            justifyContent: "space-between",
          }}
        >
          <Button
            variant="contained"
            color={displayBlock === "ACCOUNT" ? "primary" : "secondary"}
            onClick={() => setDisplayBlock("ACCOUNT")}
          >
            Account
          </Button>
          <Button
            variant="contained"
            color={displayBlock === "ACHIEVEMENTS" ? "primary" : "secondary"}
            onClick={() => setDisplayBlock("ACHIEVEMENTS")}
          >
            Achievements
          </Button>
        </Box> */}
      </Box>
      {/* {displayBlock === "ACCOUNT" && <AccountDetailsBlock />}
      {displayBlock === "ACHIEVEMENTS" && <Achievements />} */}
      {currUser && (
        <Box py={2}>
          <Typography variant="h6">{`Name: ${currUser.name}`}</Typography>
          {currUser.subscriptionTill && (
            <Typography variant="h6">{`Subscription Expires: ${dayjs(
              currUser.subscriptionTill
            ).format(DISPLAY_DATE_STRING)}`}</Typography>
          )}
        </Box>
      )}
      {currUser && !currUser.isSubscribed && <Packages />}
      <Button
        sx={{ width: 150 }}
        onClick={() => {
          dispatch(clearCurrUser());
          localStorage.removeItem(SESSION_STORAGE_KEY_JWT_TOKEN);
          navigate(URLS.HOME);
        }}
        variant="contained"
        color="secondary"
      >
        Logout
      </Button>
    </Page>
  );
};
