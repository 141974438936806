import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getQuestions } from "../../api/services/Question";
import { QuestionsBlock } from "../../components/QuestionsBlock";
import {
  clearQuestionsAndCurrQuestionIndex,
  clearResult,
  setQuestionList,
} from "../../redux/actions/questionsActions";
import { useTypedSelector } from "../../redux/store";
import { QUERY_KEY_QUESTIONS } from "../../utils/constants";

type Props = {
  chapterId: string;
};

export const ExamPractice: React.FC<Props> = ({ chapterId }) => {
  const questions = useTypedSelector((state) => state.questions.questionList);

  const dispatch = useDispatch();

  const { data } = useQuery([QUERY_KEY_QUESTIONS, chapterId], () =>
    getQuestions(chapterId)
  );

  useEffect(() => {
    if (data && data.exam_questions) {
      dispatch(
        setQuestionList(data.exam_questions.length, 0, data.exam_questions)
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearQuestionsAndCurrQuestionIndex());
      dispatch(clearResult());
    };
  }, [dispatch]);

  return (
    <>
      {questions.length ? (
        <QuestionsBlock />
      ) : (
        <Typography variant="h5">Exam practice not available</Typography>
      )}
    </>
  );
};
