import { APIS } from "../../utils/apis";
import { SubjectListItem } from "../modals/SubjectListItem";
import { SubjectProgress } from "../modals/SubjectProgress";
import { authenticatedGet } from "../utils";

export const getAllSubjects = async (): Promise<SubjectListItem[]> => {
  return (await authenticatedGet(APIS.SUBJECTS)).subjects;
};

export const getSubjectsProgress = async (): Promise<SubjectProgress[]> => {
  return await authenticatedGet(APIS.SUBJECTS_PROGRESS);
};

export const getSubject = async (
  subjectId: string
): Promise<SubjectListItem> => {
  return await authenticatedGet(APIS.getSubject(subjectId));
};
