import {
  ActionTypes,
  CLEAR_CURR_USER,
  SET_CURR_USER,
  UserState,
} from "../types";

const initialValue: UserState = {
  currUser: null,
};

export const userReducer = (
  state: UserState = initialValue,
  action: ActionTypes
): UserState => {
  switch (action.type) {
    case SET_CURR_USER:
      return {
        ...state,
        currUser: action.payload,
      };
    case CLEAR_CURR_USER:
      return {
        ...state,
        currUser: null,
      };
    default:
      return state;
  }
};
