import { Box, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../utils/muiTheme";
import { LinearProgress } from "../LinearProgress";

type Props = {
  title: string;
  progress: number;
  onClick?: () => void;
};

export const TitleWithProgress: React.FC<Props> = ({
  title,
  progress,
  onClick,
}) => {
  return (
    <Box
      onClick={onClick}
      my={3}
      mr={3}
      sx={{
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "188px",
        width: "372px",
        borderRadius: "10px",
        background: palette.white,
      }}
    >
      <Box
        sx={{
          width: "300px",
          height: "80px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          alignSelf: "center",
        }}
      >
        <Typography color={palette.black} variant="h5">
          {title}
        </Typography>
      </Box>
      {!!progress && (
        <Box px={4}>
          <LinearProgress value={progress} />
        </Box>
      )}
    </Box>
  );
};
