import { Box, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { palette } from "../../utils/muiTheme";

type Props = {
  locationLinkList: { name: string; link: string }[];
};

export const Bar: React.FC<Props> = ({ locationLinkList }) => {
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex" }}>
      {locationLinkList.map(({ name, link }, index) => (
        <Box sx={{ display: "flex" }} key={index}>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              navigate(link);
            }}
          >
            {name}
          </Link>
          {index !== locationLinkList.length - 1 && (
            <Box px={1}>
              <Typography color={palette.white}>|</Typography>
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};
