import { ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import "./App.css";
import { Routes } from "./Routes";
import { NotificationProvider } from "./components/NotificationProvider";
import { store } from "./redux/store";
import { mainTheme } from "./utils/muiTheme";

function App() {
  const queryClient = new QueryClient();

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={mainTheme}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <NotificationProvider>
              <Routes />
            </NotificationProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
