import { Box, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getChapters, getChaptersProgress } from "../../api/services/Chapter";
import { getSubject } from "../../api/services/Subject";
import { getTopic } from "../../api/services/Topic";
import { Page } from "../../components/Page";
import { SubPageHeader } from "../../components/PageHeader";
import { TitleWithProgress } from "../../components/TitleWithProgress";
import {
  QUERY_KEY_CHAPTERS,
  QUERY_KEY_CHAPTERS_PROGRESS,
  QUERY_KEY_SUBJECT,
  QUERY_KEY_TOPIC,
} from "../../utils/constants";
import { getSubjectImageName } from "../../utils/helpers";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";

export const Topic: React.FC = () => {
  const { topicId } = useParams();
  const navigate = useNavigate();

  if (!topicId) {
    throw new Error("No topic id");
  }

  const { data: topic } = useQuery([QUERY_KEY_TOPIC, topicId], () =>
    getTopic(topicId)
  );

  const { data: chapters } = useQuery([QUERY_KEY_CHAPTERS, topicId], () =>
    getChapters(topicId)
  );

  const { data: subject } = useQuery(
    [QUERY_KEY_SUBJECT, topic?.subject],
    () => getSubject(topic?.subject.toString() ?? ""),
    { enabled: !!topic }
  );

  // TODO: To be completed at next stage
  const progress = 0;
  const { data: progressOfAllChapters } = useQuery(
    [QUERY_KEY_CHAPTERS_PROGRESS, topicId],
    () => getChaptersProgress(topicId)
  );

  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <SubPageHeader
          title={topic?.name ?? ""}
          progress={progress}
          showBackgroundImg
          img={subject ? getSubjectImageName(subject.name) : ""}
        />
        <Typography
          sx={{ alignSelf: "flex-start", ml: 1 }}
          color={palette.white}
          variant="h4"
        >
          Chapters Available
        </Typography>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {chapters &&
            progressOfAllChapters &&
            chapters.map(({ id, name }) => {
              return (
                <TitleWithProgress
                  key={id}
                  title={name}
                  progress={0}
                  onClick={() => navigate(URLS.getChapter(id))}
                />
              );
            })}
        </Box>
      </Box>
    </Page>
  );
};
