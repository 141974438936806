import { Box, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../utils/muiTheme";
import { LinearProgress } from "../LinearProgress";
import { LocationBar } from "../LocationBar";

type Props = {
  title: string;
  img?: string;
  progress: number;
  showBackgroundImg: boolean;
};

export const SubPageHeader: React.FC<Props> = ({
  title,
  progress,
  showBackgroundImg,
  img,
}) => {
  return (
    <Box
      sx={{
        alignSelf: "stretch",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <Box
        mb={1}
        sx={{
          height: 185,
          alignSelf: "stretch",
          background: palette.white,
          borderRadius: "25px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        {img && (
          <Box
            sx={{ height: "92px", width: "134px" }}
            component="img"
            src={img}
            pl={8}
          />
        )}
        <Box px={2} sx={{ width: "500px" }}>
          <Box pl={1}>
            <Typography color={palette.black} variant="h4">
              {title}
            </Typography>
          </Box>
          {!!progress && <LinearProgress value={progress} />}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {showBackgroundImg && (
          <>
            <Box
              sx={{ mr: "-1px", height: "183px", width: "188px" }}
              component="img"
              src={`${process.env.PUBLIC_URL}/subject-header-2.png`}
            />
            <Box
              sx={{ height: "183px", width: "409px" }}
              component="img"
              src={`${process.env.PUBLIC_URL}/subject-header.png`}
            />
          </>
        )}
      </Box>
      <LocationBar />
    </Box>
  );
};
