import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../utils/muiTheme";

type Props = {
  packageName: string;
  price: number;
  onPurchasePlan: () => Promise<void>;
};

export const Package: React.FC<Props> = ({
  packageName,
  price,
  onPurchasePlan,
}) => {
  return (
    <Box
      sx={{
        width: "350px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
      mr={4}
    >
      <Box
        sx={{ borderBottom: 1, borderColor: palette.bottomBorder }}
        py={1}
        mb={1}
      >
        <Typography variant="h4" color={palette.lightPurple}>
          {packageName}
        </Typography>
      </Box>
      <Box
        py={2}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box
          sx={{ width: "50px", height: "50px" }}
          component="img"
          src={`${process.env.PUBLIC_URL}/price-tag.png`}
        />
        <Typography variant="h5">£{price}</Typography>
      </Box>

      <Button onClick={onPurchasePlan} variant="contained" fullWidth>
        Purchase Course
      </Button>
    </Box>
  );
};
