import { Box, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import {
  getAllSubjects,
  getSubjectsProgress,
} from "../../api/services/Subject";
import { Page } from "../../components/Page";
import { PageHeader } from "../../components/PageHeader";
import {
  QUERY_KEY_SUBJECTS,
  QUERY_KEY_SUBJECTS_PROGRESS,
} from "../../utils/constants";
import { getSubjectImageName } from "../../utils/helpers";
import { palette } from "../../utils/muiTheme";
import { SubjectCard } from "./SubjectCard";

export const SubjectList: React.FC = () => {
  const { data } = useQuery([QUERY_KEY_SUBJECTS], () => getAllSubjects());
  const { data: progressOfAllSubjects } = useQuery(
    [QUERY_KEY_SUBJECTS_PROGRESS],
    () => getSubjectsProgress()
  );

  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <PageHeader />
        <Box sx={{ width: 1280 }}>
          <Box py={4}>
            <Typography color={palette.white} variant="h5">
              Courses Available
            </Typography>
          </Box>
        </Box>
        {data && progressOfAllSubjects && (
          <Box
            sx={{
              alignSelf: "flex-start",
              display: "flex",
              flexWrap: "wrap",
            }}
          >
            {data.map(({ id, name, description }) => {
              const progress = progressOfAllSubjects
                ? progressOfAllSubjects.find((p) => p.id === parseInt(id))
                : null;

              return (
                <Box key={id} pr={2} pb={2}>
                  <SubjectCard
                    id={id}
                    name={name}
                    imgName={getSubjectImageName(name)}
                    progress={60}
                  />
                </Box>
              );
            })}
          </Box>
        )}
      </Box>
    </Page>
  );
};
