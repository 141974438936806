import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button";
import {
  PACKAGE_CONTENTS,
  PACKAGE_CONTENT_COLOURS,
} from "../../utils/constants";
import { palette } from "../../utils/muiTheme";

export const TopBlock: React.FC = () => {
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    setInterval(() => {
      setCount((old) => old + 1);
    }, 3000);
  }, []);

  return (
    <Box sx={{ width: 1240, display: "flex", justifyContent: "space-between" }}>
      <Box
        py={14}
        sx={{
          width: 535,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Typography variant="h3" color={palette.lightPurple}>
          11+
        </Typography>
        <Typography variant="h3" color={palette.lightPurple}>
          Learn and Play
        </Typography>
        <Typography variant="body1" color={palette.lightPink}>
          A Fun and Intuitive website which provides
        </Typography>
        <Box
          sx={{
            width: 330,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            color={palette.deepBlue}
            sx={{
              p: 1,
              width: "180px",
              textAlign: "center",
              background: PACKAGE_CONTENT_COLOURS[count % 3],
            }}
          >
            {PACKAGE_CONTENTS[count % 3]}
          </Typography>
          <Typography color={palette.lightPink} variant="body1">
            in one package
          </Typography>
        </Box>
        <Button variant="contained">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <PlayCircleIcon fontSize="small" />
            <Box pl={1}>Watch Demo</Box>
          </Box>
        </Button>
      </Box>
      <Box
        sx={{ width: 524, height: 522 }}
        component="img"
        src="./home-page-top-block.jpeg"
      />
    </Box>
  );
};
