import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";
import { Link } from "../Link";

export const Footer: React.FC = () => {
  return (
    <Box
      sx={{
        pt: 6,
        height: 418,
        background: palette.darkGray,
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Box>
        <Box
          py={2}
          px={1}
          component="img"
          src={process.env.PUBLIC_URL + "/Logo.png"}
        />
        <Grid width={120} container spacing={2}>
          <Grid item xs={4} md={6}>
            <Button target="_blank" href={URLS.FACEBOOK}>
              <Box
                component="img"
                src={process.env.PUBLIC_URL + "/facebook.png"}
              />
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button target="_blank" href={URLS.INSTRAGRAM}>
              <Box component="img" src={process.env.PUBLIC_URL + "/ins.png"} />
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button target="_blank" href={URLS.MAIL_TO_SUPPORT}>
              <Box
                component="img"
                src={process.env.PUBLIC_URL + "/email.png"}
              />
            </Button>
          </Grid>
          <Grid item xs={6} md={6}>
            <Button target="_blank" href={URLS.TWITTER}>
              <Box
                component="img"
                src={process.env.PUBLIC_URL + "/twitter.png"}
              />
            </Button>
          </Grid>
        </Grid>
        <Box></Box>
      </Box>
      <Box>
        <Typography sx={{ pt: 2 }} variant="h6">
          Our Product
        </Typography>
        <Link text="Support" url={URLS.SUPPORT} />
        <Link text="FAQ" url={URLS.FAQ} />
        {/* <Typography sx={{ pt: 2 }}>Guide</Typography> */}
      </Box>
      <Box>
        <Typography sx={{ pt: 2 }} variant="h6">
          Terms & Policies
        </Typography>
        <Link text="Terms of Service" url={URLS.TERMS_OF_SERVICE} />
        <Link text="Privacy Policy" url={URLS.PRIVACY} />
      </Box>
      <Box>
        <Typography sx={{ pt: 2 }} variant="h6">
          Company
        </Typography>
        <Link text="Home" url={URLS.HOME} />
        <Link text="Contact" url={URLS.CONTACT} />
      </Box>
      <Box>
        <Typography sx={{ pt: 2 }} variant="h6">
          Contact
        </Typography>
        <Typography sx={{ pt: 2 }}>Phone</Typography>
        <Link text={"E-mail"} url={URLS.MAIL_TO_SUPPORT}></Link>
      </Box>
    </Box>
  );
};
