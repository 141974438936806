import { Box, Link, Typography } from "@mui/material";
import React from "react";
import { GAMES } from "../../utils/constants";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";

type Props = {
  subjectName: string;
};

export const Games: React.FC<Props> = ({ subjectName }) => {
  const games = GAMES.filter((g) => g.subject === subjectName);

  return (
    <>
      {!!games.length && (
        <>
          <Typography
            sx={{ alignSelf: "flex-start" }}
            color={palette.white}
            variant="h4"
          >
            Related Games
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {games.map(({ game }) => (
              <Box
                key={game}
                mt={2}
                sx={{ alignSelf: "flex-start", display: "flex" }}
              >
                <Link href={URLS.getGame(game)} target="_blank">
                  <Box
                    my={1}
                    mr={2}
                    sx={{ height: "329px", width: "290px" }}
                    component="img"
                    src={`${process.env.PUBLIC_URL}/game-${game}.jpeg`}
                  />
                </Link>
              </Box>
            ))}
          </Box>
        </>
      )}
    </>
  );
};
