import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";
import {
  QUESTION_TYPE_CHOOSE_ONE,
  QUESTION_TYPE_FILL_IN,
  QUESTION_TYPE_MULTIPLE,
} from "../../utils/constants";
import { QuestionType } from "../../utils/types";
import { ChooseOneBlock } from "./ChooseOneBlock";
import { FillInBlock } from "./FillInBlock";
import { MultipleChoiceBlock } from "./MultipleChoiceBlock";

type Props = {
  isLoading: boolean;
  questionHasNotBeenAnswer: boolean;
  questionType: QuestionType;
  choices: string[];
  onQuestionAnswered: (answer: string[]) => void;
};

export const AnswerBlock: React.FC<Props> = ({
  isLoading,
  onQuestionAnswered,
  questionHasNotBeenAnswer,
  choices,
  questionType,
}) => {
  return (
    <Box
      sx={{
        maxWidth: "1500px",
        alignSelf: "center",
      }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : !questionHasNotBeenAnswer ? (
        <Box
          pt={4}
          sx={{
            alignSelf: "center",
          }}
        >
          <Typography variant="h6">Question has been answered</Typography>
        </Box>
      ) : questionType === QUESTION_TYPE_CHOOSE_ONE ? (
        <ChooseOneBlock
          choices={choices}
          onSubmit={(answer) => {
            onQuestionAnswered([answer]);
          }}
        />
      ) : questionType === QUESTION_TYPE_MULTIPLE ? (
        <MultipleChoiceBlock
          choices={choices}
          onSubmit={(answer) => {
            onQuestionAnswered(answer);
          }}
        />
      ) : questionHasNotBeenAnswer && questionType === QUESTION_TYPE_FILL_IN ? (
        <FillInBlock
          onSubmit={(answer) => {
            onQuestionAnswered([answer]);
          }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
