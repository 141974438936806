import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Bar } from "../../components/LocationBar";
import { Page } from "../../components/Page";
import { SupportEmailLink } from "../../components/SupportEmailLink";
import { URLS } from "../../utils/urls";

export const TermsOfService: React.FC = () => {
  const locationLinkList = [
    { name: "Home", link: URLS.HOME },
    { name: "Terms of Service", link: URLS.TERMS_OF_SERVICE },
  ];

  return (
    <Page>
      <Box pl={8}>
        <Bar locationLinkList={locationLinkList} />
      </Box>
      <Box pt={4} px={8}>
        <Stack spacing={4}>
          <Typography variant="h3">
            Terms of Service for 11 Plus Learn and Play
          </Typography>
          <Typography variant="body1">
            Please read these terms carefully before using our educational
            platform. By using our service, you agree to these terms and
            conditions. If you do not agree to these terms, please do not use
            our platform.
          </Typography>
          <Typography variant="h4">User Conduct</Typography>
          <Typography variant="body1">
            You are solely responsible for your use of our platform. You agree
            to use our service only for lawful purposes and in a manner that
            does not infringe the rights of any third party. You agree not to
            engage in any conduct that could damage, disable, or impair our
            platform or interfere with any other user's ability to use our
            service.
          </Typography>
          <Typography variant="h4">Intellectual Property</Typography>
          <Typography variant="body1">
            Our platform and all content and materials available on our platform
            are protected by intellectual property rights, including copyright,
            trademark, and trade secret laws. You agree not to reproduce,
            distribute, or modify any content or materials on our platform
            without our express written consent.
          </Typography>

          <Typography variant="h4">Payment and Fees</Typography>
          <Typography variant="body1">
            You agree to pay all fees associated with your use of our platform.
            We reserve the right to modify our fees at any time, and we will
            provide you with notice of any fee changes prior to implementing
            them.
          </Typography>
          <Typography variant="h4">Privacy</Typography>
          <Typography variant="body1">
            We are committed to protecting your privacy and the security of your
            personal information. Please see our Privacy Policy for more
            information on how we collect, use, and protect your personal
            information.
          </Typography>
          <Typography variant="h4">Termination</Typography>
          <Typography variant="body1">
            We reserve the right to terminate your use of our platform at any
            time for any reason. Upon termination, all licenses and rights
            granted to you under these terms of service will immediately
            terminate.
          </Typography>
          <Typography variant="h4">Limitation of Liability</Typography>
          <Typography variant="body1">
            Our platform is provided on an "as is" basis without any warranties
            of any kind. We do not warrant that our platform will be error-free
            or uninterrupted, or that any defects will be corrected.
          </Typography>
          <Typography variant="h4">Governing Law</Typography>
          <Typography variant="body1">
            These terms of service are governed by the laws of the United
            Kingdom, without regard to its conflict of laws principles.
          </Typography>
          <Typography variant="h4">Changes to these Terms</Typography>
          <Typography variant="body1">
            We reserve the right to modify these terms of service at any time.
            We will provide you with notice of any changes prior to implementing
            them.
          </Typography>
          <Typography variant="body1">
            If you have any questions or concerns about these terms of service,
            please contact us at <SupportEmailLink />.
          </Typography>
        </Stack>
      </Box>
    </Page>
  );
};
