import { Box } from "@mui/material";
import React from "react";
import { Page } from "../../components/Page";
import { CreateAccount } from "./CreateAccount";

export const Register: React.FC = () => {
  return (
    <Page>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CreateAccount />
      </Box>
    </Page>
  );
};
