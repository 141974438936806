import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { palette } from "../../utils/muiTheme";
import { HelpButton } from "./HelperButton";

type Props = {
  videoLink: string;
  title?: string;
  description?: string;
  image?: string;
  shownSolution: boolean;
};

export const QuestionWithAnswerVideo: React.FC<Props> = ({
  title,
  videoLink,
  description,
  image,
  shownSolution,
}) => {
  const [isVideoOpen, setIsVideoOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsVideoOpen(false);
  }, [videoLink]);

  return (
    <Box
      py={2}
      px={4}
      sx={{
        borderRadius: 2,
        minWidth: "830px",
        bgcolor: "white",
        alignSelf: "center",
      }}
    >
      <>
        {description && (
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>
              {title && (
                <Typography variant="h5" color={palette.black}>
                  {title}
                </Typography>
              )}
              <Box my={1} mr={1}>
                <Typography variant="body1" color={palette.black}>
                  {description}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}
      </>
      {isVideoOpen && (
        <Box p={2} sx={{ display: "flex", justifyContent: "center" }}>
          <iframe
            allow="autoplay"
            title="title"
            className="spotlightr"
            style={{ width: "800px", height: "425px" }}
            src={videoLink}
            name="videoPlayer"
          />
        </Box>
      )}
      {image && (
        <Box
          component="img"
          sx={{
            width: 800,
          }}
          alt="Image of question"
          src={image}
        />
      )}
      {videoLink && (
        <>
          <Button
            sx={{ minWidth: 150 }}
            variant="contained"
            onClick={() => setIsVideoOpen(!isVideoOpen)}
            disabled={!shownSolution}
          >
            {isVideoOpen ? "Hide Solution" : "See Solution"}
          </Button>
          <HelpButton />
        </>
      )}
    </Box>
  );
};
