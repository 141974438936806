import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Bar } from "../../components/LocationBar";
import { Page } from "../../components/Page";
import { SupportEmailLink } from "../../components/SupportEmailLink";
import { URLS } from "../../utils/urls";

export const Support: React.FC = () => {
  const locationLinkList = [
    { name: "Home", link: URLS.HOME },
    { name: "Support", link: URLS.SUPPORT },
  ];

  return (
    <Page>
      <Box pl={8}>
        <Bar locationLinkList={locationLinkList} />
      </Box>
      <Box pt={4} px={8}>
        <Stack spacing={4}>
          <Typography variant="h3">Support</Typography>
          <Typography variant="body1">
            We understand that sometimes you may need help or have questions
            while using our educational platform, and we are here to assist you.
            Our team of experts is dedicated to providing you with the support
            you need to make the most of your learning experience.
          </Typography>
          <Typography variant="body1">
            Here are some of the ways we can assist you:
          </Typography>
          <Typography variant="h4">
            Frequently Asked Questions (FAQs)
          </Typography>
          <Typography variant="body1">
            Our FAQ section provides answers to some of the most common
            questions our users have. Check here first to see if your question
            has already been answered.
          </Typography>
          <Typography variant="h4">Email Support</Typography>
          <Typography variant="body1">
            If you prefer to communicate via email, you can reach us at{" "}
            <SupportEmailLink />. We strive to respond to all inquiries within
            24 hours.
          </Typography>
          <Typography variant="h4">Phone Support</Typography>
          <Typography variant="body1">
            If you would like to speak with a member of our support team over
            the phone, please call To be Confirmed. Our support line is open
            Monday through Friday from 9am to 5pm.
          </Typography>
          <Typography variant="body1">
            We are committed to providing you with the support you need to
            succeed. Don't hesitate to reach out to us if you have any questions
            or concerns. Our team is always here to help!
          </Typography>
        </Stack>
      </Box>
    </Page>
  );
};
