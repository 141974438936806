import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { getQuestions } from "../../api/services/Question";
import {
  clearQuestionsAndCurrQuestionIndex,
  clearResult,
  setQuestionList,
} from "../../redux/actions/questionsActions";
import { useTypedSelector } from "../../redux/store";
import { QUERY_KEY_QUESTIONS } from "../../utils/constants";
import { QuestionsBlock } from "../QuestionsBlock";

type Props = {
  chapterId: string;
};

export const Quizzes: React.FC<Props> = ({ chapterId }) => {
  const questions = useTypedSelector((state) => state.questions.questionList);

  const { data, isError } = useQuery([QUERY_KEY_QUESTIONS, chapterId], () =>
    getQuestions(chapterId)
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      const { quizzes, total_num_locked_quizzes, total_num_quizzes } = data;
      dispatch(
        setQuestionList(total_num_quizzes, total_num_locked_quizzes, quizzes)
      );
    }
  }, [data, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(clearQuestionsAndCurrQuestionIndex());
      dispatch(clearResult());
    };
  }, [dispatch]);

  return (
    <Box
      sx={{ alignSelf: "center", display: "flex", justifyContent: "center" }}
    >
      {isError ? (
        <Typography variant="h6">Error to load questions</Typography>
      ) : !questions.length ? (
        <Typography variant="h6">
          No question available for this chapter
        </Typography>
      ) : (
        <QuestionsBlock />
      )}
    </Box>
  );
};
