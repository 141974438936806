import { Box, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getSubject } from "../../api/services/Subject";
import { getTopics } from "../../api/services/Topic";
import { Page } from "../../components/Page";
import { SubPageHeader } from "../../components/PageHeader";
import { TitleWithProgress } from "../../components/TitleWithProgress";
import { QUERY_KEY_SUBJECT, QUERY_KEY_TOPICS } from "../../utils/constants";
import { getSubjectImageName } from "../../utils/helpers";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";
import { Games } from "./Games";

export const Subject: React.FC = () => {
  const { subjectId } = useParams();
  const navigate = useNavigate();

  if (!subjectId) {
    throw new Error("No subject id");
  }

  const { data: subject } = useQuery([QUERY_KEY_SUBJECT, subjectId], () =>
    getSubject(subjectId)
  );

  //TODO: To be completed in next stage
  const subjectProgress = 0;
  // const { data: progressOfAllTopics } = useQuery(
  //   [QUERY_KEY_TOPICS_PROGRESS, subjectId],
  //   () => getTopicsProgress(subjectId)
  // );

  const { data: topics } = useQuery(
    [QUERY_KEY_TOPICS],
    () => getTopics(subjectId!),
    {
      enabled: !!subjectId,
    }
  );

  const subjectName = subject?.name;

  return (
    <Page>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {subjectName && (
          <SubPageHeader
            title={subjectName}
            progress={subjectProgress}
            showBackgroundImg
            img={getSubjectImageName(subjectName)}
          />
        )}

        <Typography
          sx={{ alignSelf: "flex-start" }}
          color={palette.white}
          variant="h4"
        >
          Topics Available
        </Typography>
        {topics && (
          <Box
            sx={{
              alignSelf: "flex-start",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {topics.map(({ id, name }) => {
              return (
                <TitleWithProgress
                  key={id}
                  title={name}
                  progress={0}
                  onClick={() => navigate(URLS.getTopic(id))}
                />
              );
            })}
          </Box>
        )}
        {subjectName && <Games subjectName={subjectName} />}
      </Box>
    </Page>
  );
};
