import {
  Box,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Bar } from "../../components/LocationBar";
import { Page } from "../../components/Page";
import { SupportEmailLink } from "../../components/SupportEmailLink";
import { URLS } from "../../utils/urls";

export const PrivacyPolicy: React.FC = () => {
  const locationLinkList = [
    { name: "Home", link: URLS.HOME },
    { name: "Privacy Policy", link: URLS.PRIVACY },
  ];

  const collectionOfInfo = [
    "Name and contact information (email address and telephone number)",
    "Demographic information such as age and gender",
    "Student performance and assessment data",
  ];
  const useOfInformation = [
    "To provide our educational services to our users",
    "To improve our platform and services",
    "To communicate with our users about updates and other information related to our service",
    "To comply with legal and regulatory requirements",
  ];
  const dataSecurity = [
    "Encryption of sensitive data",
    "Regular security assessments and updates",
    "Access controls and monitoring",
    "Employee training on data security best practices",
  ];
  const userRights = [
    "The right to access their personal information",
    "The right to correct any inaccuracies in their personal information",
    "The right to have their personal information deleted",
    "The right to restrict the processing of their personal information",
    "The right to data portability",
    "The right to object to the processing of their personal information",
  ];

  return (
    <Page>
      <Box pl={8}>
        <Bar locationLinkList={locationLinkList} />
      </Box>
      <Box pt={4} px={8}>
        <Stack spacing={4}>
          <Typography variant="h3">Privacy Policy</Typography>
          <Typography variant="body1">
            At 11 Plus Learn and Play, we are committed to protecting the
            privacy and security of our users' personal information. This
            privacy policy outlines the types of personal information we
            collect, how we use it, and the measures we take to keep it secure.
          </Typography>
          <Typography variant="h4">Collection of Information</Typography>

          <Typography variant="body1">
            We collect the following types of personal information from our
            users:
          </Typography>

          <List dense>
            {collectionOfInfo.map((info) => (
              <ListItem key={info}>
                <ListItemText primary={info} />
              </ListItem>
            ))}
          </List>

          <Typography variant="body1">
            We collect this information directly from our users when they
            register for our service and use our platform.
          </Typography>

          <Typography variant="h4">Use of Information</Typography>
          <Typography variant="body1">
            We use the personal information we collect for the following
            purposes:
          </Typography>

          <List dense>
            {useOfInformation.map((info) => (
              <ListItem key={info}>
                <ListItemText primary={info} />
              </ListItem>
            ))}
          </List>

          <Typography variant="h4">Data Security</Typography>
          <Typography variant="body1">
            We take appropriate measures to protect the personal information we
            collect from our users from unauthorized access, disclosure,
            alteration, and destruction. These measures include:
          </Typography>

          {
            <List dense>
              {dataSecurity.map((info) => (
                <ListItem key={info}>
                  <ListItemText primary={info} />
                </ListItem>
              ))}
            </List>
          }

          <Typography variant="h4">Data Retention</Typography>
          <Typography variant="body1">
            We retain our users' personal information only for as long as
            necessary to fulfill the purposes for which it was collected, unless
            a longer retention period is required by law.
          </Typography>

          <Typography variant="h4">User Rights</Typography>
          <Typography variant="body1">
            Our users have the following rights with respect to their personal
            information:
          </Typography>

          <List dense>
            {userRights.map((info) => (
              <ListItem key={info}>
                <ListItemText primary={info} />
              </ListItem>
            ))}
          </List>

          <Typography variant="body1">
            Users may exercise these rights by contacting us at{" "}
            <SupportEmailLink />.
          </Typography>

          <Typography variant="h4">Changes to this Privacy Policy</Typography>
          <Typography variant="body1">
            We may update this privacy policy from time to time to reflect
            changes in our information practices. If we make material changes to
            this policy, we will notify our users by email or by posting a
            notice on our platform.
          </Typography>

          <Typography variant="h4">Contact Us</Typography>
          <Typography variant="body1">
            If you have any questions or concerns about this privacy policy or
            our information practices, please contact us at <SupportEmailLink />
            .
          </Typography>
        </Stack>
      </Box>
    </Page>
  );
};
