import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import {
  clearResult,
  setCurrQuestionIndex,
} from "../../redux/actions/questionsActions";
import { useTypedSelector } from "../../redux/store";

export const NextButton: React.FC = () => {
  const dispatch = useDispatch();

  const currQuestionIndex = useTypedSelector(
    (state) => state.questions.currQuestionIndex
  );

  const questions = useTypedSelector((state) => state.questions.questionList);

  return (
    <Button
      disabled={currQuestionIndex === questions.length - 1}
      variant="contained"
      onClick={() => {
        dispatch(setCurrQuestionIndex(currQuestionIndex + 1));
        dispatch(clearResult());
      }}
    >
      Next
    </Button>
  );
};
