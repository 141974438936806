import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCurrUser } from "../../redux/actions/userActions";
import { SESSION_STORAGE_KEY_JWT_TOKEN } from "../../utils/constants";
import { palette } from "../../utils/muiTheme";
import { URLS } from "../../utils/urls";

export const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  return (
    <Box>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem
          onClick={() => {
            handleCloseUserMenu();
            dispatch(clearCurrUser());
            localStorage.removeItem(SESSION_STORAGE_KEY_JWT_TOKEN);
            navigate(URLS.HOME);
          }}
        >
          <Typography color={palette.black} textAlign="center">
            Log out
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
