import { Box, Stack } from "@mui/material";
import React, { useState } from "react";
import { ALPHABET_LETTERS } from "../../utils/constants";
import { Button } from "../Button";
import { MultipleChoiceOptionButton } from "./MultipleChoiceOptionButton";

type Props = {
  choices: string[];
  onSubmit: (answer: string[]) => void;
};

export const MultipleChoiceBlock: React.FC<Props> = ({ choices, onSubmit }) => {
  const [selectedChoices, setSelectedChoices] = useState<string[]>([]);

  const handleClick = (value: string) => {
    const index = selectedChoices.indexOf(value);
    if (index > -1) {
      const updatedSelectedChoices = [...selectedChoices];
      updatedSelectedChoices.splice(index, 1);
      setSelectedChoices(updatedSelectedChoices);
    } else {
      const updatedSelectedChoices = [...choices].filter((choice) =>
        [...selectedChoices, value].includes(choice)
      );
      setSelectedChoices(updatedSelectedChoices);
    }
  };

  return (
    <Stack mt={2} spacing={2}>
      <Box
        sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
        }}
      >
        {choices.map((choice, index) => (
          <MultipleChoiceOptionButton
            key={index}
            optionKey={ALPHABET_LETTERS[index]}
            optionValue={choice}
            onClick={() => handleClick(choice)}
            isSelected={selectedChoices.includes(choice)}
          />
        ))}
      </Box>
      <Box sx={{ alignSelf: "center" }} mt={2}>
        <Button variant="contained" onClick={() => onSubmit(selectedChoices)}>
          Submit
        </Button>
      </Box>
    </Stack>
  );
};
