import { Box, CircularProgress } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Unity, useUnityContext } from "react-unity-webgl";
import { Button } from "../../components/Button";
import { URLS } from "../../utils/urls";

export const GameContainer: React.FC = () => {
  const { unityProvider, unload, isLoaded } = useUnityContext({
    loaderUrl: "/WritingGame/CreativeWriting.loader.js",
    dataUrl: "/WritingGame/CreativeWriting.data",
    frameworkUrl: "/WritingGame/CreativeWriting.framework.js",
    codeUrl: "/WritingGame/CreativeWriting.wasm",
  });

  const navigate = useNavigate();

  return (
    <Box
      p={4}
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box pb={2}>
        <Button
          variant="contained"
          onClick={async () => {
            await unload();
            navigate(URLS.SUBJECT_LIST);
          }}
        >
          Back to Subjects
        </Button>
      </Box>
      {!isLoaded && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: 960,
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <Unity
        unityProvider={unityProvider}
        style={{ width: 960, height: 600 }}
      />
    </Box>
  );
};
