import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import {
  QUESTION_RESULT_CORRECT_ANSWER,
  QUESTION_RESULT_WRONG_ANSWER,
} from "../../utils/constants";
import { palette } from "../../utils/muiTheme";
import { QuestionResult } from "../../utils/types";

type Props = {
  optionKey: "A" | "B" | "C" | "D" | "E";
  optionValue: string;
  onClick: () => void;
  providedAnswer?: string[];
  result?: QuestionResult;
};

const getBgColour = (
  optionValue: string,
  providedAnswer?: string[],
  result?: QuestionResult
) => {
  if (!providedAnswer || !result) {
    return palette.white;
  }

  if (
    providedAnswer.includes(optionValue) &&
    result === QUESTION_RESULT_CORRECT_ANSWER
  ) {
    return palette.green;
  }

  if (
    providedAnswer.includes(optionValue) &&
    result === QUESTION_RESULT_WRONG_ANSWER
  ) {
    return palette.red;
  }

  return palette.white;
};

export const OptionButton: React.FC<Props> = ({
  optionKey,
  optionValue,
  onClick,
  providedAnswer,
  result,
}) => {
  return (
    <Box sx={{ alignSelf: "center", width: "340px" }}>
      <Button
        onClick={onClick}
        sx={{
          bgcolor: getBgColour(optionValue, providedAnswer, result),
        }}
        color="secondary"
        fullWidth
        variant="contained"
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              width: "36px",
              height: "36px",
              bgcolor: palette.grey,
              borderColor: palette.black,
              border: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {providedAnswer &&
            result &&
            providedAnswer.includes(optionValue) &&
            result === QUESTION_RESULT_CORRECT_ANSWER ? (
              <CheckIcon />
            ) : providedAnswer &&
              result &&
              providedAnswer.includes(optionValue) &&
              result === QUESTION_RESULT_WRONG_ANSWER ? (
              <CloseIcon />
            ) : (
              <Typography color={palette.black} variant="button">
                {optionKey}
              </Typography>
            )}
          </Box>
          <Box ml={2} sx={{ maxWidth: "250px" }}>
            <Typography color={palette.black} variant="body2">
              {optionValue}
            </Typography>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};
