import { Box } from "@mui/system";
import React from "react";
import {QuestionIndexBar} from './QuestionIndexBar';
import {QuestionBlock} from './QuestionBlock';
import { PreviousButton } from "./PreviousButton";
import { NextButton } from "./NextButton";

export const QuestionsBlock: React.FC = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <QuestionIndexBar />
      <QuestionBlock />
      <Box pt={4} sx={{ display: "flex" }}>
        <Box p={2} sx={{ flexGrow: 1 }}>
          <PreviousButton />
        </Box>
        <Box
          sx={{
            width: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <NextButton />
        </Box>
      </Box>
    </Box>
  );
};
