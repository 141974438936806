import {
  ActionTypes,
  CLEAR_QUESTIONS_AND_CURR_QUESTION_INDEX,
  CLEAR_RESULT,
  QuestionsState,
  SET_CURR_QUESTION_INDEX,
  SET_QUESTION_LIST,
  SET_RESULT,
} from "../types";

const initialValue: QuestionsState = {
  questionList: [],
  currQuestionIndex: 0,
  totalNumberOfQuestions: 0,
  numberOfLockedQuestions: 0,
  questionResult: null,
  correctAnswer: null,
};

export const questionsReducer = (
  state: QuestionsState = initialValue,
  action: ActionTypes
): QuestionsState => {
  switch (action.type) {
    case SET_QUESTION_LIST:
      return {
        ...state,
        questionList: action.payload.questions,
        totalNumberOfQuestions: action.payload.totalNumOfQuestions,
        numberOfLockedQuestions: action.payload.numOfLockedQuestions,
      };
    case SET_CURR_QUESTION_INDEX:
      return {
        ...state,
        currQuestionIndex: action.payload,
      };
    case CLEAR_QUESTIONS_AND_CURR_QUESTION_INDEX:
      return {
        ...state,
        questionList: [],
        currQuestionIndex: 0,
        totalNumberOfQuestions: 0,
        numberOfLockedQuestions: 0,
      };
    case SET_RESULT:
      return {
        ...state,
        questionResult: action.payload.questionResult,
        correctAnswer: action.payload.correctAnswer,
      };
    case CLEAR_RESULT:
      return {
        ...state,
        questionResult: null,
        correctAnswer: null,
      };
    default:
      return state;
  }
};
