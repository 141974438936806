import { EMAIL_ADDRESS_SUPPORT } from "./constants";

export const URLS = {
  SUBJECT_LIST: "/subject-list",
  SUBJECT: "/subject/:subjectId",
  CHAPTER: "/chapter/:chapterId",
  TOPIC: "/topic/:topicId",
  getSubject: (subjectId: string | number) => `/subject/${subjectId}`,
  getChapter: (chapterId: string | number) => `/chapter/${chapterId}`,
  getTopic: (topicId: string | number) => `/topic/${topicId}`,
  CONTACT: "/contact",
  MY_ACCOUNT: "/account",
  REGISTER: "/register",
  TERMS_OF_SERVICE: "/terms-of-service",
  GAME: "/game",
  FAQ: "/FAQ",
  SUPPORT: "/support",
  PRIVACY: "/privacy",
  HOME: "/",
  LOGIN: "/login",
  REFRESH_TOKEN: "/auth/refresh-token",
  FACEBOOK: "https://www.facebook.com/11PlusLearnAndPlay",
  INSTRAGRAM: "https://www.instagram.com/11pluslearnandplay/",
  TWITTER: "https://twitter.com",
  MAIL_TO_SUPPORT: `mailto:${EMAIL_ADDRESS_SUPPORT}`,
  getGame: (gameName: string) => `/${gameName}`,
};
