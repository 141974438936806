import { Box } from "@mui/material";
import React from "react";

export const ServicesBlock: React.FC = () => {
  return (
    <Box
      pt={8}
      pb={2}
      sx={{ display: "flex", justifyContent: "flex-start", flexWrap: "wrap" }}
    >
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/flexible-plans.jpeg`}
      />
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/engaging-and-intuitive.jpeg`}
      />
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/created-by-experts.jpeg`}
      />
      <Box
        component="img"
        src={`${process.env.PUBLIC_URL}/have-control.jpeg`}
      />
    </Box>
  );
};
