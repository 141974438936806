import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  answerQuestion,
  getAnsweredQuestions,
} from "../../api/services/Question";
import { setResult } from "../../redux/actions/questionsActions";
import { useTypedSelector } from "../../redux/store";
import {
  QUERY_KEY_ANSWERED_QUESTION,
  QUERY_KEY_QUESTIONS,
  QUESTION_RESULT_CORRECT_ANSWER,
  QUESTION_RESULT_PARTIAL_CORRECT_ANSWER,
  QUESTION_RESULT_WRONG_ANSWER,
} from "../../utils/constants";
import { QuestionType } from "../../utils/types";
import { QuestionWithAnswerVideo } from "../QuestionWithAnswerVideo";
import { AnswerBlock } from "./AnswerBlock";
import { ResetProgressButton } from "./ResetProgressButton";

export const QuestionBlock: React.FC = () => {
  const { questionList, currQuestionIndex } = useTypedSelector(
    (state) => state.questions
  );

  const currUser = useTypedSelector((state) => state.user.currUser);

  const [shownSolution, setShownSolution] = useState<boolean>(false);

  const dispatch = useDispatch();

  if (!currUser) {
    throw new Error("No user logged in");
  }

  const {
    id: questionId,
    chapter,
    difficulty,
    choices,
    video,
    text,
    question_type,
    image,
  } = questionList[currQuestionIndex];

  const queryClient = useQueryClient();

  const { data: answeredQuestion, isLoading: isAnsweredQuestionLoading } =
    useQuery(
      [QUERY_KEY_ANSWERED_QUESTION, questionId, currUser.id],
      () => getAnsweredQuestions(questionId),
      {
        staleTime: 2000,
      }
    );

  const { enqueueSnackbar } = useSnackbar();

  const checkAnswer = async (answer: string[]) => {
    try {
      const result = await answerQuestion(questionId, answer);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_QUESTIONS, chapter.id.toString()],
      });
      dispatch(setResult(result.status, result.provided_answer));
    } catch (error) {
      enqueueSnackbar(
        error instanceof Error ? error.message : "Error to check answer",
        {
          variant: "error",
        }
      );
    }
  };

  const answerSubmitHandler = async (answer: string[]) => {
    await checkAnswer(answer);
    setShownSolution(true);
  };

  const questionHasBeenAnswer =
    !!answeredQuestion &&
    !!answeredQuestion.status &&
    [
      QUESTION_RESULT_PARTIAL_CORRECT_ANSWER,
      QUESTION_RESULT_CORRECT_ANSWER,
      QUESTION_RESULT_WRONG_ANSWER,
    ].includes(answeredQuestion.status);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}
    >
      <Box pt={4} sx={{ display: "flex" }}>
        <Typography variant="body1">Difficulty level: </Typography>
        <Box px={1}>
          <Typography variant="button">{difficulty}</Typography>
        </Box>
      </Box>
      <ResetProgressButton chapterId={chapter.id.toString()} />
      <QuestionWithAnswerVideo
        videoLink={video}
        title="Question"
        description={text}
        image={image}
        shownSolution={shownSolution || questionHasBeenAnswer}
      />
      <AnswerBlock
        isLoading={isAnsweredQuestionLoading || !answerQuestion}
        questionHasNotBeenAnswer={!questionHasBeenAnswer}
        questionType={question_type as QuestionType}
        choices={choices}
        onQuestionAnswered={answerSubmitHandler}
      />
    </Box>
  );
};
