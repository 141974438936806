import { Button } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  clearResult,
  setCurrQuestionIndex,
} from "../../redux/actions/questionsActions";
import { useTypedSelector } from "../../redux/store";

export const PreviousButton = () => {
  const dispatch = useDispatch();

  const currQuestionIndex = useTypedSelector(
    (state) => state.questions.currQuestionIndex
  );

  return (
    <Button
      disabled={!currQuestionIndex}
      variant="contained"
      color="secondary"
      onClick={() => {
        dispatch(setCurrQuestionIndex(currQuestionIndex - 1));
        dispatch(clearResult());
      }}
    >
      Previous
    </Button>
  );
};
