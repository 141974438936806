import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTypedSelector } from "../../redux/store";
import { palette } from "../../utils/muiTheme";
import { QuestionResult } from "../../utils/types";

type Props = {
  optionKey: string;
  optionValue: string;
  onClick: () => void;
  isSelected: boolean;
  result?: QuestionResult;
};

const getBgColour = (
  isAnsweredCorrectly: boolean,
  isAnsweredIncorrectly: boolean,
  unselectedAnswer: boolean,
  isSelected: boolean
) => {
  if (isAnsweredCorrectly || unselectedAnswer) {
    return palette.green;
  }

  if (isAnsweredIncorrectly) {
    return palette.red;
  }

  if (isSelected) {
    return palette.lightPurple;
  }

  return palette.white;
};

export const MultipleChoiceOptionButton: React.FC<Props> = ({
  optionKey,
  optionValue,
  onClick,
  isSelected,
}) => {
  const correctAnswer = useTypedSelector(
    (state) => state.questions.correctAnswer
  );

  const isAnsweredCorrectly =
    !!correctAnswer && isSelected && correctAnswer.includes(optionValue);

  const isAnsweredIncorrectly =
    !!correctAnswer && isSelected && !correctAnswer.includes(optionValue);

  const unselectedAnswer =
    !!correctAnswer && !isSelected && correctAnswer.includes(optionValue);

  return (
    <Box m={1} sx={{ width: "340px" }}>
      <Button
        onClick={onClick}
        sx={{
          bgcolor: getBgColour(
            isAnsweredCorrectly,
            isAnsweredIncorrectly,
            unselectedAnswer,
            isSelected
          ),
        }}
        color="secondary"
        fullWidth
        variant="contained"
      >
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              width: "36px",
              height: "36px",
              bgcolor: palette.grey,
              borderColor: palette.black,
              border: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isAnsweredCorrectly || unselectedAnswer ? (
              <CheckIcon />
            ) : isAnsweredIncorrectly ? (
              <CloseIcon />
            ) : (
              <Typography color={palette.black} variant="button">
                {optionKey}
              </Typography>
            )}
          </Box>
          <Box ml={2} sx={{ maxWidth: "250px" }}>
            <Typography color={palette.black} variant="body2">
              {optionValue}
            </Typography>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};
