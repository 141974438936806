import { Box, Button, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { palette } from "../../utils/muiTheme";
import { TextField } from "../TextField";

const feedbackSchema = Yup.object().shape({
  name: Yup.string().required("Place type your name"),
  email: Yup.string().required("Place type your email address"),
  feedback: Yup.string().required("Place type your feedback"),
});

type FeedbackSchema = Yup.InferType<typeof feedbackSchema>;

const initialValues: FeedbackSchema = {
  name: "",
  email: "",
  feedback: "",
} as FeedbackSchema;

export const FeedbackForm: React.FC = () => {
  const onFormSubmit = (formValues: FeedbackSchema) => {
    console.log({ formValues });
  };

  const { handleSubmit, touched, values, errors, handleChange } = useFormik({
    initialValues: initialValues,
    validationSchema: feedbackSchema,
    onSubmit: onFormSubmit,
  });

  return (
    <Box
      px={16}
      sx={{
        height: 544,
        background: palette.orange,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box component="img" src={`${process.env.PUBLIC_URL}/feedback.jpeg`} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          width: 460,
        }}
      >
        <Typography color={palette.darkGray} variant="h3">
          Feedback
        </Typography>
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <Box py={2}>
            <Typography color={palette.darkGray} variant="body1">
              Share your valuable feedback with us !
            </Typography>
            <Box py={1}>
              <TextField
                name="name"
                value={values.name}
                onChange={handleChange}
                placeholder="Your Name"
              />
            </Box>
            <Box py={1}>
              <TextField
                name="email"
                value={values.email}
                onChange={handleChange}
                placeholder="Your Email Address"
              />
            </Box>
            <Box py={1}>
              <TextField
                multiline
                minRows={4}
                name="feedback"
                value={values.feedback}
                onChange={handleChange}
                placeholder="Your Feedback"
              />
            </Box>
          </Box>
          <Button
            sx={{ background: palette.darkGray }}
            variant="contained"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
};
