import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes as ReactRoutes, Route } from "react-router-dom";
import { refreshToken } from "./api/auth";
import { getUser } from "./api/services/User";
import { Chapter } from "./pages/Chapter";
import { Contact } from "./pages/Contact";
import { FAQ } from "./pages/FAQ";
import { GameContainer } from "./pages/GameContainer";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { MyAccount } from "./pages/MyAccount";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { Register } from "./pages/Register";
import { Subject } from "./pages/Subject";
import { SubjectList } from "./pages/SubjectList";
import { Support } from "./pages/Support";
import { TermsOfService } from "./pages/TermOfService";
import { Topic } from "./pages/Topic";
import { setCurrUser } from "./redux/actions/userActions";
import { useTypedSelector } from "./redux/store";
import { SESSION_STORAGE_KEY_JWT_TOKEN } from "./utils/constants";
import { URLS } from "./utils/urls";

const LoggedOutRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="*" element={<Home />} />
        <Route path={URLS.HOME} element={<Home />} />
        <Route path={URLS.REGISTER} element={<Register />} />
        <Route path={URLS.MY_ACCOUNT} element={<MyAccount />} />
        <Route path={URLS.SUBJECT_LIST} element={<SubjectList />} />
        <Route path={URLS.CONTACT} element={<Contact />} />
        <Route path={URLS.LOGIN} element={<Login />} />
        <Route path={URLS.SUPPORT} element={<Support />} />
        <Route path={URLS.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={URLS.TERMS_OF_SERVICE} element={<TermsOfService />} />
        <Route path={URLS.FAQ} element={<FAQ />} />
      </ReactRoutes>
    </BrowserRouter>
  );
};

const SubscribedRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="*" element={<Home />} />
        <Route path={URLS.HOME} element={<Home />} />
        <Route path={URLS.MY_ACCOUNT} element={<MyAccount />} />
        <Route path={URLS.SUBJECT_LIST} element={<SubjectList />} />
        <Route path={URLS.SUBJECT} element={<Subject />} />
        <Route path={URLS.CHAPTER} element={<Chapter />} />
        <Route path={URLS.TOPIC} element={<Topic />} />
        <Route path={URLS.CONTACT} element={<Contact />} />
        <Route path={URLS.SUPPORT} element={<Support />} />
        <Route path={URLS.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={URLS.TERMS_OF_SERVICE} element={<TermsOfService />} />
        <Route path={URLS.FAQ} element={<FAQ />} />
        {/* TODO: to remove GameContainer */}
        <Route path={URLS.GAME} element={<GameContainer />} />
      </ReactRoutes>
    </BrowserRouter>
  );
};

const UnsubscribedRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <ReactRoutes>
        <Route path="*" element={<Home />} />
        <Route path={URLS.HOME} element={<Home />} />
        <Route path={URLS.MY_ACCOUNT} element={<MyAccount />} />
        <Route path={URLS.CONTACT} element={<Contact />} />
        <Route path={URLS.SUPPORT} element={<Support />} />
        <Route path={URLS.PRIVACY} element={<PrivacyPolicy />} />
        <Route path={URLS.TERMS_OF_SERVICE} element={<TermsOfService />} />
        <Route path={URLS.FAQ} element={<FAQ />} />
      </ReactRoutes>
    </BrowserRouter>
  );
};

const LoggedInRoutes: React.FC<{ isSubscribed: boolean }> = ({
  isSubscribed,
}) => {
  if (isSubscribed) {
    return <SubscribedRoutes />;
  } else {
    return <UnsubscribedRoutes />;
  }
};

export const Routes: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem(SESSION_STORAGE_KEY_JWT_TOKEN);
    const assignCurrUser = async () => {
      if (token) {
        try {
          const currUser = await getUser();
          dispatch(setCurrUser(currUser));
        } catch (error) {
          // TODO: to be completed
          console.log("Token expired");
          try {
            const newToken = await refreshToken(token);
            localStorage.setItem(SESSION_STORAGE_KEY_JWT_TOKEN, newToken);
            const currUser = await getUser();
            dispatch(setCurrUser(currUser));
          } catch (error) {
            console.log("Unable to refresh token");
          }
        }
      }
    };

    assignCurrUser();
  }, [dispatch]);

  const user = useTypedSelector((state) => state.user.currUser);
  return user !== null ? (
    <LoggedInRoutes isSubscribed={user.isSubscribed} />
  ) : (
    <LoggedOutRoutes />
  );
};
