export const APIS = {
  REFRESH_TOKEN: "/auth/refresh-token",
  LOG_IN: "/auth",
  SIGN_UP: "/v1/users",
  USER_DETAILS: "/v1/users",
  SUBJECTS: "/v1/subjects",
  SUBJECTS_PROGRESS: "/v1/users/subjects/progress",
  CREATE_SUBSCRIPTION: "/v1/subscriptions",
  ALL_QUESTIONS: "/v1/questions",
  getAnswerQuestion: (questionId: string | number) =>
    `/v1/questions/${questionId}/answer`,
  getQuestion: (chapterId: string | number) =>
    `/v1/chapters/${chapterId}/user/questions`,
  getAnsweredQuestion: (chapterId: string | number) =>
    `/v1/questions/${chapterId}/answer`,
  getSubject: (subjectId: string) => `/v1/subjects/${subjectId}`,
  getTopic: (topicId: string) => `/v1/topics/${topicId}`,
  getTopics: (subjectId: string) => `/v1/subjects/${subjectId}/topics`,
  getTopicsProgress: (subjectId: string) =>
    `/v1/users/subjects/${subjectId}/topics/progress`,
  getResetChapter: (chapterId: string) => `/v1/chapters/${chapterId}/user/reset`,
  getChapter: (chapterId: string) => `/v1/chapters/${chapterId}`,
  getChapters: (topicId: string) => `/v1/topics/${topicId}/chapters`,
  getChaptersProgress: (topicId: string) =>
    `/v1/users/topics/742/chapters/progress`,
};
