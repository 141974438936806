import {
  Box,
  OutlinedInput,
  OutlinedInputProps,
  Typography,
} from "@mui/material";
import React from "react";
import { palette } from "../../utils/muiTheme";

type Props = {
  title?: string;
  helperText?: string | boolean;
} & OutlinedInputProps;

export const TextField: React.FC<Props> = ({ title, helperText, ...args }) => {
  return (
    <Box
      pr={1}
      sx={{
        width: "100%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {title && (
        <Typography color={palette.lightPink} variant="body1">
          {title}
        </Typography>
      )}
      <OutlinedInput
        fullWidth
        sx={{
          backgroundColor: palette.white,
          borderRadius: 1,
        }}
        {...args}
      />
      {helperText && (
        <Typography color={palette.red} variant="body1">
          {helperText}
        </Typography>
      )}
    </Box>
  );
};
