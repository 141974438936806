import { Box, Button } from "@mui/material";
import React from "react";
import {
  CHAPTER_SECTION_EXAM_PRACTICE,
  CHAPTER_SECTION_QUIZZES,
  CHAPTER_SECTION_VIDEOS,
} from "../../utils/constants";
import { ChapterSection } from "../../utils/types";

type Props = {
  currSection: ChapterSection;
  setCurrSection: (newSection: ChapterSection) => void;
};

export const ChapterSectionBar: React.FC<Props> = ({
  currSection,
  setCurrSection,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "400px",
        justifyContent: "space-between",
      }}
    >
      <Button
        variant="contained"
        color={currSection === CHAPTER_SECTION_VIDEOS ? "primary" : "secondary"}
        onClick={() => setCurrSection(CHAPTER_SECTION_VIDEOS)}
      >
        Videos
      </Button>
      <Button
        variant="contained"
        color={
          currSection === CHAPTER_SECTION_QUIZZES ? "primary" : "secondary"
        }
        onClick={() => setCurrSection(CHAPTER_SECTION_QUIZZES)}
      >
        Quizzes
      </Button>
      <Button
        variant="contained"
        color={
          currSection === CHAPTER_SECTION_EXAM_PRACTICE
            ? "primary"
            : "secondary"
        }
        onClick={() => setCurrSection(CHAPTER_SECTION_EXAM_PRACTICE)}
      >
        Exam Practice
      </Button>
    </Box>
  );
};
