import LockIcon from "@mui/icons-material/Lock";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTypedSelector } from "../../redux/store";
import {
  QUESTION_RESULT_CORRECT_ANSWER,
  QUESTION_RESULT_PARTIAL_CORRECT_ANSWER,
  QUESTION_RESULT_WRONG_ANSWER,
} from "../../utils/constants";
import { palette } from "../../utils/muiTheme";

export const QuestionIndexBar: React.FC = () => {
  const { currQuestionIndex, questionList, numberOfLockedQuestions } =
    useTypedSelector((state) => state.questions);

  let numOfLockedQUestionsLeft = numberOfLockedQuestions;
  const lockedQuestions = [];

  while (!!numOfLockedQUestionsLeft) {
    lockedQuestions.push({
      id: `LOCKED_${numOfLockedQUestionsLeft}`,
      isLocked: true,
    });
    numOfLockedQUestionsLeft--;
  }

  const questionsWithLockedQuestions = [...questionList, ...lockedQuestions];

  const getBgColour = (index: number, currIndex: number | null) => {
    if (currIndex !== null && index === currIndex) {
      return palette.yellow;
    }

    if (questionList[index]) {
      const status = questionList[index].user_answer_status;
      switch (status) {
        case QUESTION_RESULT_CORRECT_ANSWER:
          return palette.green;
        case QUESTION_RESULT_WRONG_ANSWER:
        case QUESTION_RESULT_PARTIAL_CORRECT_ANSWER:
          return palette.red;
      }
    }

    return palette.grey;
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
      {questionsWithLockedQuestions.map((question, index) => (
        <Box key={question.id} mx={1} mb={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 2,
              width: "45px",
              height: "45px",
              bgcolor: getBgColour(index, currQuestionIndex),
            }}
          >
            {question.isLocked ? (
              <LockIcon />
            ) : (
              <Typography variant="h6">{index + 1}</Typography>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};
