import { SESSION_STORAGE_KEY_JWT_TOKEN } from "../utils/constants";

const baseUrl = "https://backend.11pluslearnandplay.com";

export const post = async (url: string, body: string) => {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");

  const requestOptions = {
    method: "POST",
    headers,
    body,
  };

  const response = await fetch(`${baseUrl}${url}`, requestOptions);
  const result = await response.json();

  if (response.status !== 200 && response.status !== 201) {
    const errorMessage =
      "non_field_errors" in result
        ? result["non_field_errors"]
        : result.message;
    throw new Error(errorMessage);
  }
  return result;
};

export const authenticatedPost = async (url: string, body: string) => {
  const headers = new Headers();
  const jwtToken = localStorage.getItem(SESSION_STORAGE_KEY_JWT_TOKEN);

  if (!jwtToken) {
    throw new Error("Not authorised yet");
  }

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${jwtToken}`);

  const requestOptions = {
    method: "POST",
    headers,
    body,
  };

  const response = await fetch(`${baseUrl}${url}`, requestOptions);
  const result = await response.json();

  if (response.status !== 200 && response.status !== 201) {
    throw new Error(result.message);
  }

  return result;
};

export const authenticatedGet = async (url: string) => {
  const headers = new Headers();
  const jwtToken = localStorage.getItem(SESSION_STORAGE_KEY_JWT_TOKEN);

  if (!jwtToken) {
    throw new Error("Not authorised yet");
  }

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${jwtToken}`);

  const requestOptions = {
    method: "GET",
    headers,
  };

  const response = await fetch(`${baseUrl}${url}`, requestOptions);
  const jsonResponse = await response.json();

  if (response.status === 200) {
    return jsonResponse;
  }

  throw new Error(jsonResponse.detail);
};

export const authenticatedDelete = async (url: string) => {
  const headers = new Headers();
  const jwtToken = localStorage.getItem(SESSION_STORAGE_KEY_JWT_TOKEN);

  if (!jwtToken) {
    throw new Error("Not authorised yet");
  }

  headers.append("Authorization", `Bearer ${jwtToken}`);

  const requestOptions = {
    method: "DELETE",
    headers,
  };

  const response = await fetch(`${baseUrl}${url}`, requestOptions);

  if (response.status !== 204) {
    throw new Error(await response.json());
  }
};
