import { APIS } from "../../utils/apis";
import { AnsweredQuestion } from "../modals/AnsweredQuestion";
import { QuestionAnswer } from "../modals/QuestionAnswer";
import { Questions } from "../modals/Questions";
import { authenticatedGet, authenticatedPost } from "../utils";

export const answerQuestion = async (
  questionId: number,
  answer: string[]
): Promise<QuestionAnswer> => {
  const body = JSON.stringify({ provided_answer: answer });
  return authenticatedPost(APIS.getAnswerQuestion(questionId), body);
};

export const getQuestions = async (
  chapterId: string | number
): Promise<Questions> => {
  return authenticatedGet(APIS.getQuestion(chapterId));
};

export const getAnsweredQuestions = async (
  chapterId: string | number
): Promise<AnsweredQuestion> => {
  return await authenticatedGet(APIS.getAnsweredQuestion(chapterId));
};
