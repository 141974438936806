import { APIS } from "../../utils/apis";
import { Topic } from "../modals/Topic";
import { authenticatedGet } from "../utils";

export const getTopics = async (subjectId: string): Promise<Topic[]> => {
  return (await authenticatedGet(APIS.getTopics(subjectId))).topics;
};

export const getTopic = async (topicId: string): Promise<Topic> => {
  return await authenticatedGet(APIS.getTopic(topicId));
};

export const getTopicsProgress = async (subjectId: string): Promise<any> => {
  const x = await authenticatedGet(APIS.getTopicsProgress(subjectId));
  return null;
};
