import { palette } from "./muiTheme";

export const SESSION_STORAGE_KEY_JWT_TOKEN = "11_PLUS_JWT_TOKEN";
export const QUERY_KEY_USER_DETAILS = "QUERY_KEY_USER_DETAILS";
export const QUERY_KEY_SUBJECTS = "QUERY_KEY_SUBJECTS";
export const QUERY_KEY_SUBJECTS_PROGRESS = "QUERY_KEY_SUBJECTS";
export const QUERY_KEY_SUBJECT = "QUERY_KEY_SUBJECT";
export const QUERY_KEY_QUESTIONS = "QUERY_KEY_QUESTIONS";
export const QUERY_KEY_TOPICS = "QUERY_KEY_TOPICS";
export const QUERY_KEY_TOPICS_PROGRESS = "QUERY_KEY_TOPICS_PROGRESS";
export const QUERY_KEY_TOPIC = "QUERY_KEY_TOPIC";
export const QUERY_KEY_CHAPTERS = "QUERY_KEY_CHAPTERS";
export const QUERY_KEY_CHAPTERS_PROGRESS = "QUERY_KEY_CHAPTERS_PROGRESS";
export const QUERY_KEY_CHAPTER = "QUERY_KEY_CHAPTER";
export const QUERY_KEY_ANSWERED_QUESTION = "QUERY_KEY_ANSWERED_QUESTION";
export const CHAPTER_SECTION_VIDEOS = "VIDEOS";
export const CHAPTER_SECTION_QUIZZES = "QUIZZES";
export const CHAPTER_SECTION_EXAM_PRACTICE = "EXAM_PRACTICE";
export const getSpotlightrVideoLink = (videoId: string) =>
  `https://videos.cdn.spotlightr.com/watch/${videoId}?fallback=true`;
export const QUESTION_DIFFICULTY_LEVEL_EASY = "easy";
export const QUESTION_DIFFICULTY_LEVEL_MEDIUM = "medium";
export const QUESTION_DIFFICULTY_LEVEL_HARD = "hard";
export const QUESTION_LOCKED = "LOCKED";
export const NO_QUESTIONS = "NO QUESTIONS";
export const QUESTION_TYPE_FILL_IN = "fill_in";
export const QUESTION_TYPE_CHOOSE_ONE = "choose_one";
export const QUESTION_TYPE_MULTIPLE = "multiple";
export const QUESTION_RESULT_CORRECT_ANSWER = "CORRECT_ANSWER";
export const QUESTION_RESULT_WRONG_ANSWER = "WRONG_ANSWER";
export const QUESTION_RESULT_PARTIAL_CORRECT_ANSWER = "PARTIAL_CORRECT_ANSWER";
export const QUESTION_RESULT_NOT_ANSWERED = "NOT_ANSWERED";
export const NUM_OF_EASY_QUESTIONS = 5;
export const NUM_OF_MEDIUM_QUESTIONS = 5;
export const NUM_OF_HARD_QUESTIONS = 5;
const PACKAGE_CONTENT_EDUCATION = "Education";
const PACKAGE_CONTENT_CREATIVITY = "Creativity";
const PACKAGE_CONTENT_FUN = "FUN";
export const PACKAGE_CONTENTS = [
  PACKAGE_CONTENT_EDUCATION,
  PACKAGE_CONTENT_CREATIVITY,
  PACKAGE_CONTENT_FUN,
];
export const PACKAGE_CONTENT_COLOURS = [
  palette.pink,
  palette.turquoise,
  palette.orange,
];
export const EMAIL_ADDRESS_SUPPORT = "11pluslearnandplay@gmail.com";

export const GAMES: { subject: string; game: string }[] = [
  {
    subject: "English",
    game: "creative-writing",
  },
  {
    subject: "Non Verbal Reasoning",
    game: "tangrams",
  },
  {
    subject: "Verbal Reasoning",
    game: "creative-writing",
  },
  {
    subject: "Maths",
    game: "tug-of-war",
  },
  {
    subject: "English",
    game: "hanging-monkey",
  },

  {
    subject: "Maths",
    game: "flappy-bird",
  },
  {
    subject: "Verbal Reasoning",
    game: "flappy-bird",
  },
  {
    subject: "Non Verbal Reasoning",
    game: "flappy-bird",
  },

  {
    subject: "Maths",
    game: "racing",
  },
  {
    subject: "Verbal Reasoning",
    game: "racing",
  },
];

export const PHONE_NUMBER_JEFF = "+44 7448 005439";
export const EMAIL_ADDRESS_JEFF = "jeffrey_paul@hotmail.co.uk";
export const PRICE_ID_MONTHLY_PLAN = "price_1PrixzFHP5xRBUPTLNgXxZPY";
export const PRICE_ID_QUARTERLY_PLAN = "price_1Mg9I2FHP5xRBUPTZGFGAXoy";
export const PRICE_ID_ANNUAL_PLAN = "price_1Mg9HNFHP5xRBUPTT4GijrzZ";
export const ALPHABET_LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export const DISPLAY_DATE_STRING = "DD MMM YYYY";
