import { Question } from "../../api/modals/Question";
import { QuestionResult } from "../../utils/types";
import {
  ActionTypes,
  CLEAR_QUESTIONS_AND_CURR_QUESTION_INDEX,
  CLEAR_RESULT,
  SET_CURR_QUESTION_INDEX,
  SET_QUESTION_LIST,
  SET_RESULT,
} from "../types";

export const setQuestionList = (
  totalNumOfQuestions: number,
  numOfLockedQuestions: number,
  questions: Question[]
): ActionTypes => ({
  type: SET_QUESTION_LIST,
  payload: {
    totalNumOfQuestions,
    numOfLockedQuestions,
    questions,
  },
});

export const setCurrQuestionIndex = (index: number): ActionTypes => ({
  type: SET_CURR_QUESTION_INDEX,
  payload: index,
});

export const clearQuestionsAndCurrQuestionIndex = (): ActionTypes => ({
  type: CLEAR_QUESTIONS_AND_CURR_QUESTION_INDEX,
});

export const setResult = (
  questionResult: QuestionResult,
  correctAnswer: string[]
): ActionTypes => ({
  type: SET_RESULT,
  payload: {
    questionResult,
    correctAnswer,
  },
});

export const clearResult = (): ActionTypes => ({ type: CLEAR_RESULT });
